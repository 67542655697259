import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import * as templateHelper from "./templateHelper";

export const TemplateToggle = (props) => {
  const {
    path,
    value,
    values,
    customValue,
    ...rest
  } = props;

  const stateValue = useSelector(state => templateHelper.getValue(state.templateBuilderEx, path));

  const show = useMemo(() => {
    let result = stateValue;

    if (customValue !== undefined) return customValue;

    if (value) {
      result = value === stateValue;
    } else if (values) {
      result = values.includes(stateValue);
    }

    return result;
  }, [
    value,
    values,
    customValue,
    stateValue,
  ]);

  return (
    <>
      {show && (
        <div
          className={rest.className || ""}
          style={rest.style}
        >
          {rest.children}
        </div>
      )}
    </>
  );
};

TemplateToggle.isTemplateToggle = true;
