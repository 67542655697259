import React from 'react';
import { I18n } from 'react-redux-i18n';

import { GridColumn, Header } from 'semantic-ui-react';
import GridRow from 'semantic-ui-react/dist/commonjs/collections/Grid/GridRow';
import moment from 'moment';
import config from '../../../constants';
import currencyHelper from '../../../helpers/currencyHelper';

interface ITicketingHeaderProps {
  ticketData: any;
  displayOrgCurrency: boolean;
}

export const TicketingHeader = ({ ticketData, displayOrgCurrency }: ITicketingHeaderProps) => {
  const id = ticketData?.id;
  const currency = displayOrgCurrency ? ticketData.organization.currency : ticketData.currency;
  const amount = displayOrgCurrency
    ? ticketData.amount * ticketData.financials.exchangeRate
    : ticketData.amount;
  const details = ticketData?.details || [];
  const linkedTickets = details?.filter((x: any) => x.ticket).length;
  const soldAtLocal = ticketData?.soldAtLocal;
  const refundedAmount = ticketData?.financials?.refundedAmount;
  // prettier-ignore
  const date = soldAtLocal ? moment(soldAtLocal).format(config.INVERSION_FULL_DATE_TIME_PERIODS_FORMAT) : 'N/A';

  return (
    <div className="ui grid row ticketing-header">
      <GridRow className="label-container word-wrap">
        <GridColumn width={6}>
          <Header as="h4">{I18n.t('ticketing.edit.header.sale-id')}</Header>
          <span className="label">{id}</span>
        </GridColumn>
        <GridColumn width={6}>
          <Header as="h4">{I18n.t('ticketing.edit.header.date')}</Header>
          <span className="label">{date}</span>
        </GridColumn>
        <GridColumn width={4}>
          <Header as="h4">
            {I18n.t('ticketing.edit.header.receiptNumber')}
          </Header>
          <span className="label">{ticketData?.receiptNumber}</span>
        </GridColumn>
      </GridRow>

      <GridRow className="label-container word-wrap" style={{ paddingTop: 0 }}>
        <GridColumn width={6} style={{ paddingTop: '20px' }}>
          <Header as="h4">{I18n.t('ticketing.edit.header.amount')}</Header>
          {currency} {currencyHelper.formatCurrency(amount, currency)}
        </GridColumn>
        <GridColumn width={6} style={{ paddingTop: '20px' }}>
          <Header as="h4">
            {I18n.t('ticketing.edit.header.amount-redunded')}
          </Header>
          {currencyHelper.formatCurrency(refundedAmount, currency)}
        </GridColumn>
        <GridColumn width={4} style={{ paddingTop: '20px' }}>
          <Header as="h4">
            {I18n.t('ticketing.edit.header.linked-tickets')}
          </Header>
          <span className="label">{linkedTickets}</span>
        </GridColumn>
      </GridRow>
    </div>
  );
};
