import update from '../helpers/update';
import { UPLOAD_SUCCESS } from './upload';
import get from 'lodash.get';
import { CLEAR } from './general';
import { isArrayWithItems } from '../helpers/arrayHelper';
import { TICKET_CREATE_SUCCESS, COUPON_CREATE_SUCCESS } from '../pages/ticketing/store/ticketingTypes';

export const RESULT_SET = 'template-builder-ex/RESULT_SET';
export const TEMPLATE_MODEL_SET = 'template-builder-ex/TEMPLATE_MODEL_SET';
export const ITEM_VISIBILITY_CHANGE =
  'template-builder-ex/ITEM_VISIBILITY_CHANGE';
export const ITEMS_VISIBILITY_CHANGE =
  'template-builder-ex/ITEMS_VISIBILITY_CHANGE';
export const ITEM_STATUS_CHANGE = 'template-builder-ex/ITEM_STATUS_CHANGE';
export const ITEM_OPTIONS_CHANGE = 'template-builder-ex/ITEM_OPTIONS_CHANGE';
export const CLEAR_ITEMS = 'template-builder-ex/CLEAR_ITEMS';

export const clearItems = (names) => {
  return {
    type: CLEAR_ITEMS,
    payload: {
      names: names
    }
  };
};

export const changeItemVisibility = (name, visible, clearOnHide = true) => {
  return {
    type: ITEM_VISIBILITY_CHANGE,
    payload: {
      name,
      visible,
      clearOnHide: clearOnHide
    }
  };
};

export const changeItemsVisibility = (names, visible, clearOnHide = true) => {
  return {
    type: ITEMS_VISIBILITY_CHANGE,
    payload: {
      names,
      visible,
      clearOnHide
    }
  };
};

export const changeItemStatus = (name, disabled) => {
  return {
    type: ITEM_STATUS_CHANGE,
    payload: {
      name,
      disabled
    }
  };
};

export const hideControl = (name) => {
  return changeItemVisibility(name, false);
};

export const showControl = (name) => {
  return changeItemVisibility(name, true);
};

export const hideControls = (names) => {
  return changeItemsVisibility(names, false);
};

export const showControls = (names) => {
  return changeItemsVisibility(names, true);
};

export const clearControl = (name) => {
  return setResultValue(name, undefined);
};

export const changeItemOptions = (name, options) => {
  return {
    type: ITEM_OPTIONS_CHANGE,
    payload: {
      name,
      options
    }
  };
};

export const setResultValue = (path, value) => ({
  type: RESULT_SET,
  payload: {
    path,
    value
  }
});

export const setTemplateModel = (model) => ({
  type: TEMPLATE_MODEL_SET,
  payload: model
});

const initialState = {
  data: {},
  state: {}
};
const templateBuilderReducerEx = (state = initialState, action) => {
  let newState = state;
  switch (action.type) {
    case ITEMS_VISIBILITY_CHANGE: {
      action.payload.names.forEach((name) => {
        newState = update.set(
          newState,
          `state.${name}.visible`,
          action.payload.visible
        );

        if (!action.payload.visible && action.payload.clearOnHide) {
          newState = update.set(newState, `data.${name}`, undefined);
        }
      });
      break;
    }

    case ITEM_VISIBILITY_CHANGE: {
      newState = update.set(
        newState,
        `state.${action.payload.name}.visible`,
        action.payload.visible
      );
      if (!action.payload.visible && action.payload.clearOnHide) {
        newState = update.set(
          newState,
          `data.${action.payload.name}`,
          undefined
        );
      }

      break;
    }

    case ITEM_STATUS_CHANGE: {
      newState = update.set(
        newState,
        `state.${action.payload.name}.disabled`,
        action.payload.disabled
      );
      break;
    }

    case ITEM_OPTIONS_CHANGE: {
      break;
    }

    case CLEAR: {
      newState = initialState;
      break;
    }

    case UPLOAD_SUCCESS: {
      newState = update.set(
        newState,
        `data.${action.payload.path}`,
        action.payload.data
      );
      break;
    }

    case RESULT_SET: {
      newState = update.set(
        newState,
        `data.${action.payload.path}`,
        action.payload.value
      );
      break;
    }

    case CLEAR_ITEMS: {
      if (isArrayWithItems(action.payload.names)) {
        action.payload.names.forEach((name) => {
          newState = update.set(newState, `data.${name}`, undefined);
        });
      }
      break;
    }

    case TEMPLATE_MODEL_SET: {
      newState = action.payload;
      newState = update.set(newState, 'inited', true);
      break;
    }

    case TICKET_CREATE_SUCCESS:
      const newTicket = get(action, 'payload.newTicket');

      if (newTicket) {
        const tickets = get(newState, 'data.tickets');
        const ticketIndex = tickets.findIndex(
          (ticket) =>
            ticket.template.value.identifier ===
            newTicket.template.value.identifier
        );

        newState = update(newState, {
          data: {
            tickets: {
              [ticketIndex]: {
                id: { $set: newTicket.id }
              }
            }
          }
        });

        const availableTickets = get(newState, 'data.tickets').map(
          (ticket) => ticket.id
        );

        newState = update.set(
          newState,
          'data.availableTickets',
          availableTickets
        );
      }
      break;

    case COUPON_CREATE_SUCCESS:
      const newCoupon = get(action, 'payload.newCoupon');

      if (newCoupon) {
        const coupons = get(newState, 'data.coupons');
        const couponIndex = coupons.findIndex(
          (coupon) =>
            coupon.instances &&
            coupon.instances[0] &&
            newCoupon &&
            newCoupon.instances &&
            newCoupon.instances[0] &&
            coupon.instances[0].code ===
              newCoupon.instances[0].code
        );

        newState = update(newState, {
          data: {
            coupons: {
              [couponIndex]: {
                id: { $set: newCoupon.id },
                instances: { $set: newCoupon.instances }
              }
            }
          }
        });

        const availableCoupons = get(newState, 'data.coupons').map(
          (coupon) => coupon.id
        );

        newState = update.set(
          newState,
          'data.availableCoupons',
          availableCoupons
        );
      }
      break;

    default:
      return newState;
  }

  return newState;
};

export default templateBuilderReducerEx;
