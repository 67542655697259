import React from 'react';

import { Translate } from 'react-redux-i18n';

import { TemplatePage } from '../../../../components/common/pager';
import { TemplateHeader } from '../../../../components/template/templateHeader';
import { TemplateCurrency } from '../../../../components/template/templateCurrency';
import { InfoBox } from '../../../../components/common/infoBox';
import Spinner from '../../../../components/common/spinner';

export const getVipPlacesTab = (props) => {
  const {
    availableSeats,
    campaignAmbassadorProgramRecordId,
    isUpdateAvailableSeatsLoading,
    onChangeAvailableSeats,
    onUpdateAvailableSeats,
  } = props;

  return (
    <TemplatePage
      id="vipPlaces"
      path="edit-program-applicationQuestions"
      i18nKey="eventPrograms.template.page.vip-places.title"
    >
      <TemplateHeader
        as="h2"
        label="eventPrograms.template.page.vip-places.header"
      />
      
      <InfoBox
        header="eventPrograms.template.page.vip-places.info-box.header"
        content="eventPrograms.template.page.vip-places.info-box.content"
      />

      <div>
        <TemplateCurrency
          isolated
          hideInputLabel
          id="vipPlacesAllocated"
          label="eventPrograms.template.page.vip-places.places-allocated.label"
          value={availableSeats}
          onChange={(path, value) => onChangeAvailableSeats(value)}
        />

        <a
          isolated
          style={{
            cursor: "pointer",
          }}
          onClick={() => onUpdateAvailableSeats({ id: campaignAmbassadorProgramRecordId, newAvailableSeats: availableSeats })}
        >
          <Translate value="eventPrograms.template.page.vip-places.places-allocated.update-button" />
        </a>

        {isUpdateAvailableSeatsLoading && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(255, 255, 255, 0.7)",
            }}
          >
            <Spinner />
          </div>
        )}
      </div>
        
    </TemplatePage>
  );
};
