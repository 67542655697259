import React, { useEffect } from 'react';
import { TemplatePage } from '../../../components/common/pager';
import {
  EXTERNAL_REGO_FLOW_OPTIONS,
  LOGO_OPTIONS,
  TIER_COUNT,
  paths
} from '../constants/template';
import { TemplateInput } from '../../../components/template/templateInput';
import { TemplateCheckbox } from '../../../components/template/templateCheckbox';
import { TemplateCurrency } from '../../../components/template/templateCurrency';
import { TemplateToggle } from '../../../components/template/templateToggle';
import { TemplateDropdown } from '../../../components/template/templateDropdown';
import {
  externalIntegrationSystem,
  externalIntegrationSystemOptions
} from '../constants';
import { TemplateHorizontalRule } from '../../../components/template/templateHorizontalRule';
import { TemplateHeader } from '../../../components/template/templateHeader';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { useAppDispatch } from '../../../hooks';

import './index.css';

const TierData = ({ onChange, targetValidation, index, currency }: any) => {
  // prettier-ignore
  // @ts-ignore
  const builderData = useSelector((state: RootState) => state.templateBuilderEx?.data) as any;
  const template = builderData?.template?.value;
  const currentTier = template?.tiers?.[index];
  const dispatch = useAppDispatch();

  useEffect(() => {
    // prettier-ignore
    const regoFlow = currentTier?.options?.organizationListing?.externalRegistrationFlow;

    if (currentTier?.enabled === false) {
      return;
    }

    // set deffaults
    if (regoFlow?.visible == null) {
      onChange(paths.TIERS.regoFlowVisible(index), true);
    }
    if (regoFlow?.searchable == null) {
      onChange(paths.TIERS.searchable(index), true);
    }
    if (regoFlow?.logoBehaviour == null) {
      onChange(paths.TIERS.logoBehaviour(index), LOGO_OPTIONS[0].value);
    }
    if (currentTier?.enabled == null) {
      onChange(paths.TIERS.enabled(index), true);
    }
  }, [currentTier]);

  const onTierEnabledChanged = (path: string, value: boolean) => {
    if (value === false) {
      onChange(`template.value.tiers.${index}`, { key: currentTier.key });
    }
    onChange(path, value);
  };

  return (
    <>
      <TemplateCheckbox
        path={paths.TIERS.enabled(index)}
        inputLabel={`event.template.page.tiers.event-tier-${index}.enabled`}
        className="label-header"
        onChange={onTierEnabledChanged}
      />

      <TemplateToggle path={paths.TIERS.enabled(index)}>
        <TemplateInput
          path={paths.TIERS.name(index)}
          label={`event.template.page.tiers.event-tier-${index}.name-label`}
          onChange={onChange}
        />

        <TemplateCurrency
          path={paths.TIERS.target(index)}
          label={`event.template.page.tiers.event-tier-${index}.target-label`}
          validation={targetValidation}
          onChange={onChange}
          currency={currency}
        />

        <TemplateToggle
          path={paths.EXTERNAL_SYSTEM}
          values={EXTERNAL_REGO_FLOW_OPTIONS}
        >
          <div className="rego-header">
            <TemplateHeader
              as="h3"
              label="event.template.page.tiers.rego-flow.title"
            />
          </div>

          <TemplateCheckbox
            path={paths.TIERS.regoFlowVisible(index)}
            inputLabel="event.template.page.tiers.rego-flow.visible"
            onChange={onChange}
          />

          <TemplateCheckbox
            path={paths.TIERS.searchable(index)}
            inputLabel="event.template.page.tiers.rego-flow.searchable"
            onChange={onChange}
          />

          <TemplateDropdown
            path={paths.TIERS.logoBehaviour(index)}
            label="event.template.page.tiers.rego-flow.logo"
            onChange={onChange}
            options={LOGO_OPTIONS}
          />

          <TemplateToggle path={paths.TIERS.regoFlowVisible(index)}>
            <TemplateCheckbox
              path={paths.TIERS.randomOrder(index)}
              inputLabel="event.template.page.tiers.rego-flow.randomOrder"
              onChange={onChange}
            />

            <TemplateCheckbox
              path={paths.TIERS.displayAboutText(index)}
              inputLabel="event.template.page.tiers.rego-flow.about-text"
              onChange={onChange}
            />

            <TemplateCheckbox
              path={paths.TIERS.allowDonations(index)}
              inputLabel="event.template.page.tiers.rego-flow.donations"
              onChange={onChange}
            />
          </TemplateToggle>
        </TemplateToggle>
      </TemplateToggle>
    </>
  );
};

export const getTiersTab = (props: any) => {
  const { onChange, clearItems, templateBuilder, currency } = props;

  const onHasTiersChange = (path: string, value: any) => {
    if (!value) {
      const teirs = [];
      for (let i = 0; i < TIER_COUNT; i++) {
        teirs.push(`template.value.tiers.${i}.name`);
        teirs.push(`template.value.tiers.${i}.target`);
      }
      clearItems();
    }

    onChange(path, value);
  };

  const onExternalSystemChange = (path: string, value: any) => {
    onChange(paths.TICKET_BUTTON_SETTINGS_USE_URL, undefined);
    onChange(paths.TICKET_BUTTON_SETTINGS_USE_ORG_URL, undefined);
    onChange(paths.TICKET_BUTTON_SETTINGS_PARAMETER_NAME, undefined);
    onChange(paths.TICKET_BUTTON_SETTINGS_PARAMETER_TYPE, undefined);
    if (value === externalIntegrationSystem.active) {
      onChange(paths.TICKET_BUTTON_SETTINGS_PARAMETER_NAME, 'charityId');
      onChange(paths.TICKET_BUTTON_SETTINGS_USE_ORG_URL, true);
    }
    else if (value === externalIntegrationSystem.director) {
      onChange(paths.TICKET_BUTTON_SETTINGS_PARAMETER_NAME, 'cid');
      onChange(paths.TICKET_BUTTON_SETTINGS_USE_URL, false);
    } else if (value === externalIntegrationSystem.raceRoster) {
      onChange(paths.TICKET_BUTTON_SETTINGS_PARAMETER_NAME, 'grassrootz');
      onChange(paths.TICKET_BUTTON_SETTINGS_USE_ORG_URL, true);
    } else if (value === externalIntegrationSystem.njuko) {
      onChange(paths.TICKET_BUTTON_SETTINGS_PARAMETER_TYPE, 'route');
      onChange(paths.TICKET_BUTTON_SETTINGS_USE_URL, true);
    } else if (value === externalIntegrationSystem.registerNow) {
      onChange(paths.TICKET_BUTTON_SETTINGS_PARAMETER_NAME, 'context');
      onChange(paths.TICKET_BUTTON_SETTINGS_USE_URL, true);

      // prettier-ignore
      const customButtonText = templateBuilder?.template?.value?.customText?.ticketButtonText;
      // prettier-ignore
      const customEmbeddedText = templateBuilder?.template?.value?.customText?.embedded;

      if (!customButtonText && !customEmbeddedText) {
        onChange('template.value.customText.ticketButtonText', 'Register Now');
        onChange('template.value.customText.embedded', {
          confirmation: {
            termsAndConditions: `I understand and agree that if I choose to fundraise for this event with Grassrootz, Register Now will share my name, email, address, phone number, and event information with Grassrootz for purposes of creating my Grassrootz account, facilitating my fundraising for this event, and for use in accordance with the Grassrootz <a href="https://grassrootz.com/terms-conditions/" target="_blank">Terms and Conditions</a> and <a href="https://grassrootz.com/privacy-policy/" target="_blank">Privacy Policy</a>. <ul><li>Grassrootz and the charity that I choose to fundraise for will receive my contact details and be allowed to contact me.</li><li>If I already have a Grassrootz account, a fundraising page will be added to my existing account.</li><li>If I do not already have a Grassrootz account, Grassrootz will email me to complete my account sign up.</li><li>A fundraising page for this event will be automatically added to my Grassrootz account.</li></ul>`
          },
          thankyou: {
            contentMessage:
              'Grassrootz will setup your fundraising page and notify you via email, instructions on how to access your page will be detailed there as well.'
          }
        });
      }
    } else {
      onChange(paths.TICKET_BUTTON_SETTINGS_PARAMETER_NAME, undefined);
      onChange(paths.TICKET_BUTTON_SETTINGS_USE_URL, undefined);
    }

    onChange(path, value);
  };

  return (
    <TemplatePage id="tiers" i18nKey="event.template.page.tiers.title">
      <TemplateCheckbox
        path={paths.HAS_TIERS}
        inputLabel="event.template.page.setup.event-has-tiers.label"
        onChange={onHasTiersChange}
      />

      <TemplateToggle path={paths.HAS_TIERS}>
        <TemplateDropdown
          path={paths.EXTERNAL_SYSTEM}
          label="event.template.page.tiers.external-system.label"
          onChange={onExternalSystemChange}
          options={externalIntegrationSystemOptions}
        />

        <TemplateToggle path={paths.EXTERNAL_SYSTEM} value="active">
          <TemplateInput
            path={paths.EXTERNAL_SYSTEM_ID}
            label="event.template.page.tiers.external-system-id.label"
            onChange={onChange}
          />

          <TemplateInput
            path={paths.EXTERNAL_SYSTEP_API_KEY}
            label="event.template.page.tiers.external-system-api-key.label"
            onChange={onChange}
          />
        </TemplateToggle>

        <TemplateHorizontalRule />

        {[...Array(TIER_COUNT)].map((_, index) => (
          <>
            <TierData {...props} index={index} currency={currency} />
            {index < TIER_COUNT - 1 && <TemplateHorizontalRule />}
          </>
        ))}
      </TemplateToggle>
    </TemplatePage>
  );
};
