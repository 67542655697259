import React, { Component } from 'react';
import RecordResultMessage from '../../../../components/common/recordResult';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Record, getUrl } from '../../../../modules/bin/utility';
import { pageActions } from '../reducer';
import * as templateBuilderActions from '../../../../modules/template-builder-ex';
import * as generalActions from '../../../../modules/general';
import { setRedirectUrl } from '../../../../modules/session';
import {
  pageStatuses,
  pageTypes,
  paths,
  FORMBUILDER_REGISTRATION_PATH,
  fitnessMeasure
} from '../../constants';
import Spinner from '../../../../components/common/spinner';
import FundraiserTemplate from '../templateEx';
import { TemplateOptions } from '../../../../components/template/templateOptions';
import { campaignContract } from '../../create/templates';
import { Common } from '../../../../components';
import { Link } from 'react-router-dom';
import get from 'lodash.get';
import BasePage from '../../../../components/common/basePageView';
import clear from '../../../../components/clear';
import config from '../../../../constants/index';
import { I18n } from 'react-redux-i18n';
import { Grid, Button } from 'semantic-ui-react';
import BulkUploadModal from '../../../../components/common/bulkUploadModal';
import BulkUploadDescription from '../bulkUploadDescription';
import { stringToArray } from '../../../../helpers/arrayHelper';

class CreatePage extends Component {
  constructor(props) {
    super(props);
    props.pageActions.getCampaignRecord(props.match.params.id);
  }

  /**
   * Executes a save instruction from the template builder and passes it to the Grassrootz API.
   * @param {object} data - the modified record (same as state)
   */
  onSave = (data) => {
    data.id = 0;
    data.urlPath = getUrl(data.name);
    data.status = pageStatuses.Live;
    if (data.type === pageTypes.FUNDRAISER) {
      this.props.pageActions.createFundraiserRecord(data);
    } else if (data.type === pageTypes.TEAM) {
      this.props.pageActions.createTeamRecord(data);
    } else {
      this.props.pageActions.createCollectionRecord(data);
    }
  };

  clearMessages = () => {
    this.props.pageActions.clearRecordMessages('saveRecord');
  };

  initTemplateBuilderModel = () => {
    const campaign = this.props.record.data.data;
    const teamId = parseInt(this.props.match.params.teamId);
    let type = null;
    let hideTeam = true;
    let about = null;
    const campaignAbout = get(campaign, 'template.value.about');
    const teamAbout = get(campaign, 'template.value.defaultTeamAbout');
    const fundraiserAbout = get(
      campaign,
      'template.value.defaultFundraiserAbout'
    );
    const collectionAbout = get(
      campaign,
      'template.value.defaultCollectionAbout'
    );
    const formBuilder = get(campaign, FORMBUILDER_REGISTRATION_PATH);
    const fundraisersEnabled = get(campaign, 'fundraisers.enabled');
    const collectionsEnabled = get(campaign, 'collections.enabled');
    const campaignUrl = get(campaign, 'url');

    const campaignPrimaryColor = get(
      campaign,
      'template.value.style.primaryColor'
    );
    const campaignSecondaryColor = get(
      campaign,
      'template.value.style.secondaryColor'
    );
    const campaignInvertPrimaryTextColor = get(
      campaign,
      'template.value.style.invertPrimaryTextColor'
    );
    const campaignInvertSecondaryTextColor = get(
      campaign,
      'template.value.style.invertSecondaryTextColor'
    );

    //fitness
    const isFitnessEnabled =
      get(campaign, 'fitnessSetup.enabled') &&
      get(campaign, 'template.value.fitnessSetup.allowPageTracking');

    const allowedTrackedStatistics = get(
      campaign,
      'fitnessSetup.allowedTrackedStatistics'
    );
    const fitnessThermometersToShow = get(
      campaign,
      'template.value.fitnessSetup.thermometersToShow'
    ) || [];

    const showFitnessDistanceTarget =
      isFitnessEnabled &&
      stringToArray(allowedTrackedStatistics).some(
        (item) => item === fitnessMeasure.distance
      ) &&
      fitnessThermometersToShow.some(
        (item) => item === fitnessMeasure.distance
      );
    const showFitnessTimeTarget =
      isFitnessEnabled &&
      stringToArray(allowedTrackedStatistics).some(
        (item) => item === fitnessMeasure.time
      ) &&
      fitnessThermometersToShow.some((item) => item === fitnessMeasure.time);
    const showFitnessNumberOfStepsTarget =
      isFitnessEnabled &&
      stringToArray(allowedTrackedStatistics).some(
        (item) => item === fitnessMeasure.numberOfSteps
      ) &&
      fitnessThermometersToShow.some(
        (item) => item === fitnessMeasure.numberOfSteps
      );

    //custom activities
    const isCustomActivitiesEnabled = get(
      campaign,
      campaignContract.customActivitiesEnable
    );
    const isCustomActivitiesThermobarEnabled = get(
      campaign,
      campaignContract.customActivitiesValue1ThermometerEnabled
    );

    const showCustomActivityTarget =
      isCustomActivitiesEnabled && isCustomActivitiesThermobarEnabled;
    const customActivitiesValue1Unit = get(
      campaign,
      campaignContract.customActivitiesValue1Unit
    );

    if (fundraisersEnabled) {
      type = pageTypes.FUNDRAISER;
      hideTeam = campaign.teams.enabled ? false : true;
    } else {
      type = pageTypes.TEAM;
      hideTeam = true;
    }

    const model = new TemplateOptions()
      .setModel({
        type: type,
        isTeamEnabled: campaign.teams.enabled,
        isFundraiserEnabled: fundraisersEnabled,
        isCollectionsEnabled: collectionsEnabled,
        defaultCampaignAbout: campaignAbout,
        defaultTeamAbout: teamAbout,
        defaultFundraiserAbout: fundraiserAbout,
        defaultCollectionAbout: collectionAbout,
        campaignId: campaign.id,
        isPublic: true,
        campaignUrl: campaignUrl,
        organization: {
          name: campaign.organization.name
        },
        fitnessSetup: campaign.fitnessSetup
      })
      .setValue('template.value.about', about)
      .setValue(FORMBUILDER_REGISTRATION_PATH, formBuilder)
      .setValue(
        'template.value.style.primaryColor',
        campaignPrimaryColor || '#FF0000'
      )
      .setValue(
        'template.value.style.secondaryColor',
        campaignSecondaryColor || '#0000FF'
      )
      .setValue(
        'template.value.style.invertPrimaryTextColor',
        !!campaignInvertPrimaryTextColor
      )
      .setValue(
        'template.value.style.invertSecondaryTextColor',
        !!campaignInvertSecondaryTextColor
      )
      .setValue('showFitnessDistanceTarget', showFitnessDistanceTarget)
      .setValue('showFitnessTimeTarget', showFitnessTimeTarget)
      .setValue(
        'showFitnessNumberOfStepsTarget',
        showFitnessNumberOfStepsTarget
      )
      .setValue('showCustomActivityTarget', showCustomActivityTarget)
      .setValue('customActivitiesValue1Unit', customActivitiesValue1Unit)
      .hide('manager')
      .hide('parentId')
      .hide('template.value.heroImagePath')
      .hide('coloursGroup')
      .hide('coloursInvertionGroup')
      .hide('dateAndTime')
      .hide(campaignContract.timeAndPlace)
      .hide(campaignContract.timeExtraDetails)
      .hide(campaignContract.location);
      
    if (teamId) {
      model.setValue('teamId', teamId);
      model.hide('teamId');
      model.hide('type');
    }

    if (hideTeam) {
      model.hide('teamId');
    }

    if (!collectionsEnabled) {
      model.hide('collectionId');
    }

    if (!showFitnessDistanceTarget) {
      model.hide('fitnessDistanceTarget');
    }
    if (!showFitnessTimeTarget) {
      model.hide('fitnessTimeTarget');
    }
    if (!showFitnessNumberOfStepsTarget) {
      model.hide('fitnessNumberOfStepsTarget');
    }

    if (!showCustomActivityTarget) {
      model.hide('customActivities.value1.target');
    }

    //custom extra fields
    model.hide(campaignContract.coordinatorFirstName);
    model.hide(campaignContract.coordinatorLastName);
    model.hide(campaignContract.coordinatorEmail);
    model.hide(campaignContract.coordinatorPhone);

    this.props.templateBuilderActions.setTemplateModel(model.get());
  };

  goToPagesList = (historyState) => {
    let url = `/campaigns/view/fundraisers/${this.props.match.params.id}`;
    if (this.props.redirectUrl) {
      url = this.props.redirectUrl;
      this.props.setRedirectUrl();
    }
    this.props.history.push(url, historyState);
  };

  onBulkUploadButtonClick = () => {
    this.props.pageActions.onChange(paths.IS_BULK_UPLOAD_MODEL_OPENED, true);
  };

  onBulkUploadModalClose = () => {
    this.resetUploadData();
    this.props.pageActions.onChange(paths.IS_BULK_UPLOAD_MODEL_OPENED, false);
  };

  resetUploadData = () => {
    this.props.pageActions.onChange(paths.BULK_UPLOAD_DATA, null);
  };

  onUploadCSVClick = () => {
    this.props.pageActions.bulkUpload({
      ...this.props.bulkUploadData,
      campaignID: this.props.match.params.id
    });
  };

  onBulkUploadSucces = () => {
    const orderByDate = {
      key: 'createdAtLocal',
      direction: 'desc'
    };

    this.goToPagesList({
      campaignFundraisersOrdering: {
        ...orderByDate
      }
    });
  };

  render() {
    const isLoading =
      Record.notStarted(this.props.record) ||
      Record.isRecordLoading(this.props.record);
    const isSaving = Record.isRecordLoading(this.props.saveRecord);
    const isSuccess = Record.isSuccess(this.props.saveRecord);
    const isUploadRecordLoading = Record.isRecordLoading(
      this.props.bulkUploadRecord
    );
    const isBulkUploadSuccess = Record.isSuccess(this.props.bulkUploadRecord);

    if (isLoading) {
      return <Spinner />;
    }

    if (isSuccess) {
      this.goToPagesList();
    }

    if (isBulkUploadSuccess) {
      this.onBulkUploadSucces();
    }

    if (!this.props.isTemplateInited) {
      this.initTemplateBuilderModel();
    }

    const campaign = this.props.record.data.data;

    return (
      <BasePage>
        <RecordResultMessage
          record={this.props.saveRecord}
          onDismiss={this.clearMessages}
        />

        <BulkUploadModal
          isOpened={this.props.isBulkUploadModelOpened}
          title={I18n.t('general.bulk-upload.page.title')}
          description={<BulkUploadDescription />}
          onClose={this.onBulkUploadModalClose}
          path={paths.CSV_URL_PATH}
          uploadedFileUrl={get(this.props, paths.CSV_URL_PATH)}
          onRemoveFile={this.resetUploadData}
          onUploadClick={this.onUploadCSVClick}
          isUploadRecordLoading={isUploadRecordLoading}
        />

        <Grid>
          <Grid.Row columns={2} style={{ padding: '0px' }}>
            <Grid.Column width={6}>
              <Common.PageHeader name="Create page" hideImage>
                <Link to={config.DEFAULT_CAMPAIGNS_URL} push>
                  <Common.PageHeader.Breadcrumb label="Campaigns" />
                </Link>
                <Link to={`/campaigns/view/fundraisers/${campaign.id}`} push>
                  <Common.PageHeader.Breadcrumb label={campaign.name} />
                </Link>
                <Common.PageHeader.Breadcrumb label="Create page" />
              </Common.PageHeader>
            </Grid.Column>
            <Grid.Column width={10}>
              <Button
                style={{ float: 'right' }}
                onClick={this.onBulkUploadButtonClick}
              >
                {I18n.t('general.bulk-upload.bulk-upload-button')}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <FundraiserTemplate
          header="template.fundraiser.content.detail.header"
          onSave={this.onSave}
          isLoading={isSaving}
        />
      </BasePage>
    );
  }
}

const mapState = ({ campaignPage, templateBuilderEx }) => {
  return {
    record: campaignPage.campaignRecord,
    bulkUploadData: campaignPage.bulkUploadData,
    saveRecord: campaignPage.saveRecord,
    isTemplateInited: templateBuilderEx.inited,
    isBulkUploadModelOpened: campaignPage.isBulkUploadModelOpened,
    bulkUploadRecord: campaignPage.bulkUploadRecord
  };
};

const mapDispatch = (dispatch) => {
  return {
    pageActions: bindActionCreators(pageActions, dispatch),
    setRedirectUrl: bindActionCreators(setRedirectUrl, dispatch),
    generalActions: bindActionCreators(generalActions, dispatch),
    templateBuilderActions: bindActionCreators(templateBuilderActions, dispatch)
  };
};

const CreatePageContainer = clear(connect(mapState, mapDispatch)(CreatePage));
export default CreatePageContainer;
