import {
  generalFormBuilderPath,
  customFieldCategory
} from '../../constants/formBuilder';

export const saleContract = {
  campaignId: 'campaignId',
  amount: 'amount',
  currency: 'currency',
  firstName: 'customer.firstName',
  lastName: 'customer.lastName',
  email: 'customer.email',
  businessName: 'customer.businessName',
  formbuilder: `${generalFormBuilderPath}.${customFieldCategory.SALES}`,
  issueInvoice: 'issueInvoice',
  oneClickDonating: 'options.donating.enabled',
  oneClickFundraising: 'options.fundraising.enabled',
  shippingInformation: 'shippingInformation',
  shippingType: 'shipping.type',
  shippingCost: 'shipping.cost',
  details: 'details',
  fee: 'financials.fee',
  feeCovered: 'financials.feeCovered'
};

export const getTicketContract = (index) => {
  return {
    campaignId: `details.${index}.campaignId`,
    ticketId: `details.${index}.ticketId`,
    ticketName: `details.${index}.ticket.name`,
    amount: `details.${index}.price`,
    firstName: `details.${index}.customer.firstName`,
    lastName: `details.${index}.customer.lastName`,
    email: `details.${index}.customer.email`,
    formbuilder: `details.${index}.ticket.${generalFormBuilderPath}`,
    issueInvoice: `details.${index}.issueInvoice`
  };
};

export const getMerchandiseContract = (index) => {
  return {
    campaignId: `details.${index}.campaignId`,
    merchandiseName: `details.${index}.merchandiseSku.name`,
    merchandiseImage: `details.${index}.merchandiseSku.merchandise.mainImagePath`,
    amount: `details.${index}.price`,
    firstName: `details.${index}.customer.firstName`,
    lastName: `details.${index}.customer.lastName`,
    email: `details.${index}.customer.email`,
    formbuilder: `details.${index}.merchandiseSku.${generalFormBuilderPath}`
  };
};

export const instalmentFormatter = (x) => {
  if (x?.count > 0) {
    return `${x?.paidCount}/${x?.count}`;
  }
  return 'N/A';
};

export const templateTabs = {
  SALE: {
    key: 'invoice-information',
    getTabName() {
      return 'invoice-information';
    }
  },
  TICKET: {
    key: 'ticket',
    getTabName(index) {
      return `ticket-${index}`;
    }
  },
  MERCHANDISE: {
    key: 'merchandise',
    getTabName(index) {
      return `merchandise-${index}`;
    }
  }
};
