import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Translate } from 'react-redux-i18n';

import * as validationHelper from '../../../helpers/validationHelper';

import * as templateBuilderActions from '../../../modules/template-builder-ex';
import { reset, searchEntities } from '../../../modules/autocomplete';

import TemplateBuilderContainerEx from '../../../components/template/builder/indexEx';

import { TemplatePage } from '../../../components/common/pager';
import { TemplateHeader } from '../../../components/template/templateHeader';
import { TemplateHorizontalRule } from '../../../components/template/templateHorizontalRule';

import CurrencyHelper from '../../../helpers/currencyHelper';
import moment from 'moment';

import { CONTEXT_MENU_KEYS } from '../../../constants/contextMenuEntries';

import { TARGET_VALIDATION } from '../../../constants/validationModels';
import { TemplateAutocomplete } from '../../../components/template/templateAutocomplete';
import { pageTypesStrings } from '../../../constants/pageTypes';
import { paths } from './constants/template';
import { TemplateCheckbox } from '../../../components/template/templateCheckbox';

class TransferDonationSubscriptionTemplateView extends Component {
  constructor(props) {
    super(props);

    this.disableSameCampaign = {
      required: true,
      disableSameCampaign: false,
      campaignId: this.props.donationCampaignId
    };

    this.required = {
      required: true
    };

    this.customCheck = {
      sameEntity: true
    };
  }

  onChange = (path, value, item) => {
    this.props.setResultValue(path, value);
  };

  onPageChange = (path, value, item) => {
    if (value) {
      const separatorIndex = value.indexOf('_');
      const id = value.substring(separatorIndex + 1, value.length);
      const type = value.substring(0, separatorIndex);

      if (type === pageTypesStrings.FUNDRAISER) {
        this.props.clearControl(paths.TEAM_ID);
        this.props.clearControl(paths.COLLECTION_ID);
        this.onChange(paths.PAGE_TYPE, type);
        this.onChange(paths.FUNDRAISER_ID, id);
      } else if (type === pageTypesStrings.TEAM) {
        this.props.clearControl(paths.FUNDRAISER_ID);
        this.props.clearControl(paths.COLLECTION_ID);
        this.onChange(paths.PAGE_TYPE, type);
        this.onChange(paths.TEAM_ID, id);
      } else if (type === pageTypesStrings.COLLECTION) {
        this.props.clearControl(paths.FUNDRAISER_ID);
        this.props.clearControl(paths.TEAM_ID);
        this.onChange(paths.PAGE_TYPE, type);
        this.onChange(paths.COLLECTION_ID, id);
      }
    } else {
      this.props.clearControl(paths.FUNDRAISER_ID);
      this.props.clearControl(paths.PAGE_TYPE);
      this.props.clearControl(paths.TEAM_ID);
      this.props.clearControl(paths.COLLECTION_ID);
      this.onChange(paths.PAGE_TYPE, 'campaign');
    }

    this.onChange(path, value);
  };

  getPageRequest = (campaignId) => {
    if (!this.props.selectedCampaignId && !campaignId) {
      return;
    }

    return {
      page: 1,
      pageSize: 75,
      resultType: 'Full',
      orderBy: {
        key: 'name',
        direction: 'asc'
      },
      filters: [
        {
          key: 'campaignId',
          operator: 'EqualTo',
          value: campaignId || this.props.selectedCampaignId
        },
        {
          key: 'isCollectionOrFundraiserOrTeam',
          operator: 'EqualTo',
          value: 'true'
        }
      ]
    };
  };

  getPageItem = (item) => {
    return {
      text: item.name,
      subtext: item.urlPath,
      value:
        item.type +
        '_' +
        (item.fundraiserId || item.teamId || item.collectionId)
    };
  };

  onCampaignChange = (path, value, item) => {
    const isCampaignValid = validationHelper.isValid(
      this.disableSameCampaign,
      value
    );

    if (value && isCampaignValid) {
      this.props.resetAutocomlite(paths.PAGE_ID);
      this.props.showControl(paths.PAGE_ID);
      this.props.searchEntities(
        paths.PAGE,
        paths.PAGE_ID,
        this.getPageRequest(value)
      );
    } else {
      this.props.hideControl(paths.PAGE_ID);
    }

    this.onChange(path, value, item);
  };

  getCampaignItem = (item) => {
    if (item.organization) {
      return {
        text: item.name,
        subtext: `${item.organization.name}`,
        value: item.id
      };
    } else {
      return {
        text: item.name,
        value: item.id
      };
    }
  };

  getCampaignRequest = () => {
    return {
      page: 1,
      pageSize: 75,
      resultType: 'Full',
      includeDataTemplate: true,
      orderBy: {
        key: 'name',
        direction: 'asc'
      },
      filters: [
        { key: 'status', operator: 'EqualTo', value: 1 },
        {
          key: 'organizationId',
          operator: 'EqualTo',
          value: this.props.donationOrganizationId
        }
      ]
    };
  };

  render() {
    const validationErrorMessage = validationHelper.getValidationErrorMessage(
      this.disableSameCampaign,
      this.props.selectedCampaignId
    );
    const firstName = this.props.firstName;
    const lastName = this.props.lastName;
    const email = this.props.email;
    const amount = this.props.amount;
    const currency = this.props.currency;
    const formattedAmount = CurrencyHelper.formatCurrency(
      amount,
      currency);
    const startedAt = moment(this.props.startedAt).format('DD MMM YYYY hh:mm a');
    const campaignId = this.props.campaignId;
    const campaignUrlFull = this.props.campaignUrlFull;
    const campaignName = this.props.campaignName;
    const donationCount = this.props.donationCount;
    const raisedAmount = this.props.raisedAmount;
    const formattedRaisedAmount = CurrencyHelper.formatCurrency(
      raisedAmount,
      currency
    );

    return (
      <TemplateBuilderContainerEx
        onSave={this.props.onSave}
        isLoading={this.props.isLoading}
      >
        <TemplatePage id="setup" i18nKey="template.page.setup">
          <div className="donation-details">
            <TemplateHeader
              as="h3"
              label="template.page.content.donation.transfer-donation-subscription.header-section.header"
            />

            <div className="donation-details-content">
              <Translate value="template.page.content.donation.transfer-donation-subscription.header-section.content" />
            </div>
          </div>

          <TemplateHorizontalRule />

          <TemplateHeader
            as="h3"
            label="template.page.content.donation.transfer-donation-subscription.details-section.header"
          />

          <div isolated className="template-content-container">
            <div isolated className="template-name">
              <Translate value="general.firstName" />: <span>{firstName}</span>
            </div>

            <div className="template-name">
              <Translate value="general.lastName" />: <span>{lastName}</span>
            </div>

            <div className="template-email">
              <Translate value="general.emailAddress" />: <span>{email}</span>
            </div>

            <div className="template-amount">
              <Translate value="general.amount" />: <span>{currency} {formattedAmount}</span>
            </div>

            {(donationCount && donationCount > 0 && formattedRaisedAmount) &&
              <div className="template-total-raised">
                <Translate value="general.total-raised" />: <span>{currency} {formattedRaisedAmount} - {donationCount} donation(s)</span>
              </div>
            }

            <div className="template-started-on">
              <Translate value="general.started-on" />: <span>{startedAt}</span>
            </div>

            {(campaignId && campaignName && campaignUrlFull) &&
              <div className="template-campaign">
                <Translate value="template.page.content.donation.transfer-donation-subscription.details-section.current-campaign-name" />
                :&nbsp;{campaignName}&nbsp;
                (<a href={campaignUrlFull} target="_blank" rel="noreferrer">
                  View
                </a>
                &nbsp;-&nbsp; 
                <a href={CONTEXT_MENU_KEYS.CAMPAIGN_MENU.EDIT_CAMPAIGN.getUrl({ id: campaignId })} target="_blank" rel="noreferrer">
                  Edit
                </a>)
              </div>}
          </div>

          <TemplateHorizontalRule />

          <TemplateHeader
            as="h3"
            label="template.page.content.donation.transfer-donation-subscription.campaign-details.header"
          />

          {(donationCount && donationCount > 0) && 
            <TemplateCheckbox
              id="include-existing-payments"
              path={paths.INCLUDE_EXISTING_PAYMENTS}
              inputLabel="template.page.content.donation.transfer-donation-subscription.campaign-details.include-existing-payments.label"
              subtext="template.page.content.donation.transfer-donation-subscription.campaign-details.include-existing-payments.subtext"
              onChange={this.onChange}
            />
          }

          <TemplateAutocomplete
            id="donation-campaign"
            entity="page/campaign"
            path={paths.CAMPAIGN_ID}
            selected="campaign"
            label="template.donation.content.detail.campaign.label"
            placeholder="template.donation.content.detail.campaign.placeholder"
            validation={this.disableSameCampaign}
            onChange={this.onCampaignChange}
            request={this.getCampaignRequest}
            getItem={this.getCampaignItem}
            subtext={
              'template.page.content.donation.transfer-donation-subscription.campaign-autocomplete-subtext'
            }
            validationErrorMessage={validationErrorMessage}
          />

          <TemplateAutocomplete
            id="donation-page"
            entity="page"
            path={paths.PAGE_ID}
            selected="page"
            label="template.donation.content.detail.page.label"
            placeholder="template.donation.content.detail.page.placeholder"
            onChange={this.onPageChange}
            request={this.getPageRequest}
            getItem={this.getPageItem}
          />

          <TemplateHorizontalRule />

          <div isolated className="notes-details">
            <div className="notes-details-title">
              <Translate value="template.page.content.donation.transfer-donation-subscription.notes.header" />
            </div>
            <div className="list-details">
              <li>
                <Translate value="template.page.content.donation.transfer-donation-subscription.notes.list-items.item-1" />
              </li>
              <li>
                <Translate value="template.page.content.donation.transfer-donation-subscription.notes.list-items.item-2" />
              </li>
              <li>
                <Translate value="template.page.content.donation.transfer-donation-subscription.notes.list-items.item-3" />
              </li>
              <li>
                <Translate value="template.page.content.donation.transfer-donation-subscription.notes.list-items.item-4" />
              </li>
            </div>
          </div>
        </TemplatePage>
      </TemplateBuilderContainerEx>
    );
  }
}

const mapState = (state) => {
  return {
    donationOrganizationId: state.recurring.record.data.data.organizationId,
    donationCampaignId: state.recurring.record.data.data.campaignId,
    firstName: state.recurring.record.data.data.donor.firstName,
    lastName: state.recurring.record.data.data.donor.lastName,
    email: state.recurring.record.data.data.donor.email,
    amount: state.recurring.record.data.data.amount,
    currency: state.recurring.record.data.data.currency,
    startedAt: state.recurring.record.data.data.startedAtLocal,
    campaignName: state.recurring.record.data.data.campaign?.name,
    campaignUrlFull: state.recurring.record.data.data.campaign?.urlFull,
    campaignId: state.recurring.record.data.data.campaign?.id,
    donationCount: state.recurring.record.data.data.donationCount,
    raisedAmount: state.recurring.record.data.data.raisedAmount,
    selectedCampaignId: state.templateBuilderEx.data.campaignId
  };
};

const mapDispatch = (dispatch) => {
  return {
    ...bindActionCreators(templateBuilderActions, dispatch),
    resetAutocomlite(path) {
      dispatch(reset(path));
    },
    searchEntities(entity, path, request) {
      dispatch(searchEntities(entity, path, request));
    }
  };
};

const TransferDonationSubscriptionTemplate = connect(
  mapState,
  mapDispatch
)(TransferDonationSubscriptionTemplateView);
export default TransferDonationSubscriptionTemplate;
