import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { I18n, Translate } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Menu, Dropdown } from 'semantic-ui-react';
import get from 'lodash.get';

import * as generalActions from '../../modules/general';

import { General } from '../../pages';
import { deleteImpersonation, logOut } from '../../modules/session';

import logo from '../../images/logo.svg';
import { grecaptcha } from '../../helpers/grecaptcha';
import { FeatureFlag } from '../../modules/featureflags';

class Navigation extends Component {
  getLocationBaseRoute() {
    const pathList = this.props.location.pathname.split('/');
    return pathList.length >= 2 ? pathList[1] : null;
  }

  getItemBaseRoute(route) {
    const pathList = route.split('/');
    return pathList.length >= 1 ? pathList[0] : null;
  }

  onLanguageChange(value) {
    this.props.changeLocale(value);
  }

  onClickDeleteImpersonation = () => {
    grecaptcha(this.props.deleteImpersonation, 'SessionOperation');
  };

  onLogOutClick = () => {
    grecaptcha(this.props.logOut, 'SessionOperation');
  };

  render() {
    const { general } = this.props;
    const baseRoute = this.getLocationBaseRoute();
    const {
      isSystemAdmin,
      isOrganizationAdmin,
      isEventAdmin,
      isCampaignAdmin,
      isEventOrganizerAdmin
    } = this.props;
    const showWebhooksMenuOption = isSystemAdmin || isOrganizationAdmin;

    const organizationRoute = isOrganizationAdmin
      ? `organization/edit/${get(this.props, 'organization.id')}/about`
      : '';

    const menuItems = [
      {
        key: 'my-organization.title',
        value: get(this.props.organization, 'name'),
        route: organizationRoute,
        visible: isOrganizationAdmin
      },
      {
        key: 'dashboard.title',
        route: `organizations/dashboard/${get(this.props, 'organization.id')}`,
        visible: isOrganizationAdmin
      },
      {
        key: 'organization.title',
        route: 'organizations',
        visible: isSystemAdmin
      },
      {
        key: 'event.title',
        route: 'events/live',
        visible: isSystemAdmin || isEventAdmin || isEventOrganizerAdmin
      },
      {
        key: 'programs.title',
        route: 'vip-program/programs',
        visible:
          isSystemAdmin ||
          isOrganizationAdmin ||
          isCampaignAdmin ||
          isEventAdmin ||
          isEventOrganizerAdmin
      },
      {
        key: 'campaign.title',
        route: 'campaigns/live',
        visible:
          isOrganizationAdmin ||
          isSystemAdmin ||
          isCampaignAdmin ||
          isEventAdmin
      },
      {
        key: 'fundraiser.title',
        route: 'fundraisers',
        visible:
          isOrganizationAdmin ||
          isSystemAdmin ||
          isCampaignAdmin ||
          isEventAdmin
      },
      {
        key: 'recurring.title',
        route: 'recurring/all',
        visible: isSystemAdmin || isOrganizationAdmin
      },
      {
        key: 'donation.title',
        route: 'donations',
        visible: isSystemAdmin || isOrganizationAdmin
      },
      {
        key: 'sale.title',
        route: 'sales',
        visible: isSystemAdmin || isOrganizationAdmin || isCampaignAdmin
      },
      {
        key: 'account.title',
        route: `accounts/${isSystemAdmin ? 'all' : 'admins'}`,
        visible: isSystemAdmin || isOrganizationAdmin
      },
      {
        key: 'terminal.title',
        route: `tap-n-donate/assigned`,
        visible: isSystemAdmin
      }
    ];

    const menuItemEntries = menuItems.map((item, idx) => {
      const alignment =
        (isOrganizationAdmin && idx === 0) ||
        (isSystemAdmin && idx === 2) ||
        ((isEventAdmin || isEventOrganizerAdmin) && idx === 3) ||
        (isCampaignAdmin && idx === 4)
          ? 'right'
          : null;

      if (!item.visible) {
        // return null instead  of empty component as it would require a key
        return null;
      }

      return (
        <Menu.Item
          key={item.key}
          active={this.getItemBaseRoute(item.route) === baseRoute}
          position={alignment}
        >
          <Link className="item-link" to={`/${item.route}`}>
            {item.value || <Translate value={item.key} />}
          </Link>
        </Menu.Item>
      );
    });

    return (
      <div>
        <Menu className="main-menu" pointing secondary fixed="top">
          <Link to="/">
            <img src={logo} className="logo" alt="logo" />
          </Link>
          {menuItemEntries}
          <Dropdown
            id="dropdownNavigation"
            icon="chevron down"
            text={I18n.t('general.greeting', {
              name: this.props.firstName
            })}
            className="link item"
          >
            <Dropdown.Menu id="dropdownMenu">
              <Dropdown.Item
                id="accountSettingsItem"
                as="a"
                href={`/accounts/settings/${this.props.currentUserId}`}
              >
                Account Settings
              </Dropdown.Item>
              <FeatureFlag feature="exportData">
                <Dropdown.Item
                  id="accountSettingsItem"
                  as="a"
                  href="/export-history"
                >
                  Export History
                </Dropdown.Item>
              </FeatureFlag>
              {showWebhooksMenuOption ? (
                <Dropdown.Item id="accountSettingsItem" as="a" href="/webhooks">
                  Webhooks
                </Dropdown.Item>
              ) : null}
              {this.props.impersonated && (
                <Dropdown.Item onClick={this.onClickDeleteImpersonation}>
                  {I18n.t('account.remove-impersonate-action')}
                </Dropdown.Item>
              )}
              <Dropdown.Item onClick={this.onLogOutClick}>
                {I18n.t('general.log-out')}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Menu>

        <General.LanguageModal
          open={general.modals.language}
          languages={general.languages.data}
          value={general.languages.current}
          onChange={this.onLanguageChange.bind(this)}
          onClose={() =>
            this.props.toggleModal('language', !general.modals.language)
          }
        />
      </div>
    );
  }
}

const mapState = ({ general, session }) => {
  const currentUserId = get(session, 'account.id');

  const {
    isOrganizationAdmin,
    isSystemAdmin,
    isCampaignAdmin,
    isEventAdmin,
    isEventOrganizerAdmin,
    impersonated
  } = session;
  return {
    general,
    isOrganizationAdmin,
    isSystemAdmin,
    isEventAdmin,
    isCampaignAdmin,
    isEventOrganizerAdmin,
    impersonated,
    currentUserId: currentUserId,
    organization: session.organization,
    firstName: get(session, 'account.firstName')
  };
};

const mapDispatch = (dispatch) => {
  return {
    ...bindActionCreators(generalActions, dispatch),
    deleteImpersonation(verificationToken = null) {
      dispatch(deleteImpersonation(verificationToken));
    },
    logOut(verificationToken = null) {
      dispatch(logOut(verificationToken));
    }
  };
};

export default withRouter(connect(mapState, mapDispatch)(Navigation));
