import update from '../../../helpers/update';
import constants from "../../../constants";
import * as entityHandlers from '../../../modules/bin/entityHandlers';
import get from 'lodash.get';
import { Record } from '../../../modules/bin/utility';
import { CLEAR } from '../../../modules/general';
import { fundraisingManagerAccountOperation } from '../../../constants/fundraisingManagerAccountOperation';
import { templateKeys } from '../../../constants/templateKeys';
import '../../../helpers/aggregateUrlExtension';
import {
  listFilterAggregate,
  listFilterOperator
} from '../../../constants/aggregate';
import {
  getCustomFieldsWithAnswers,
  hasFormbuilderQuestions,
  getQuestionsFromModel
} from '../../../helpers/formBuilderHelper';
import { UPLOAD_SUCCESS } from '../../../modules/upload';
import { paths, FORMBUILDER_REGISTRATION_PATH } from '../constants';
import {
  generalFormBuilderPath,
  fieldCategoryKey,
  oldCustomFieldCategory
} from '../../../constants/formBuilder';
import { getDataWithQuestionFields, getTimeAndDateObject } from '../../../helpers/dataCaptureHelper';
import { aboutHandler } from '../../../helpers/pageAboutHelper';

export const CREATE_REQUESTED = 'campaign-page-create/CREATE_REQUESTED';
export const CREATE_SUCCESS = 'campaign-page-create/CREATE_SUCCESS';
export const CREATE_FAILURE = 'campaign-page-create/CREATE_FAILURE';

export const UPDATE_REQUESTED = 'campaign-page-update/UPDATE_REQUESTED';
export const UPDATE_SUCCESS = 'campaign-page-update/UPDATE_SUCCESS';
export const UPDATE_FAILURE = 'campaign-page-update/UPDATE_FAILURE';

export const UPDATE_STATUS_REQUESTED =
  'campaign-page-update/UPDATE_STATUS_REQUESTED';
export const UPDATE_STATUS_SUCCESS =
  'campaign-page-update/UPDATE_STATUS_SUCCESS';
export const UPDATE_STATUS_FAILURE =
  'campaign-page-update/UPDATE_STATUS_FAILURE';

export const RESULT_SET = 'campaign-page-create/RESULT_SET';
export const RECORD_CLEAR = 'campaign-page-create/RECORD_CLEAR';
export const ERROR_CLEAR = 'campaign-page-create/ERROR_CLEAR';

export const GET_REQUESTED = 'campaign-page-update/GET_REQUESTED';
export const GET_SUCCESS = 'campaign-page-update/GET_SUCCESS';
export const GET_FAILURE = 'campaign-page-update/GET_FAILURE';

export const GET_PAGE_REQUESTED = 'campaign-page-update/GET_PAGE_REQUESTED';
export const GET_PAGE_SUCCESS = 'campaign-page-update/GET_PAGE_SUCCESS';
export const GET_PAGE_FAILURE = 'campaign-page-update/GET_PAGE_FAILURE';

export const FUNDRAISERS_COUNT_REQUESTED =
  'campaign-page-update/FUNDRAISERS_COUNT_REQUESTED';
export const FUNDRAISERS_COUNT_SUCCESS =
  'campaign-page-update/FUNDRAISERS_COUNT_SUCCESS';
export const FUNDRAISERS_COUNT_FAILURE =
  'campaign-page-update/FUNDRAISERS_COUNT_FAILURE';

export const BULK_UPLOAD_REQUESTED =
  'campaign-page-create/BULK_UPLOAD_REQUESTED';
export const BULK_UPLOAD_SUCCESS = 'campaign-page-create/BULK_UPLOAD_SUCCESS';
export const BULK_UPLOAD_FAILURE = 'campaign-page-create/BULK_UPLOAD_FAILURE';

export const ON_VALUE_CHANGE = 'campaign-page-update/ON_VALUE_CHANGE';

const initialState = {
  timestamp: new Date().getTime(),
  record: Record.getDefaultState(true),
  campaignRecord: Record.getDefaultState(),
  saveRecord: Record.getDefaultState(),
  statusRecord: Record.getDefaultState(),
  fundraisersCountRecord: Record.getDefaultState(),
  [paths.BULK_UPLOAD_RECORD]: Record.getDefaultState(),
  isBulkUploadModelOpened: false,
  [paths.BULK_UPLOAD_DATA]: null
};

const campaignPageReducer = (state = initialState, action) => {
  let newState = state;

  switch (action.type) {
    case CLEAR: {
      newState = initialState;
      break;
    }

    case CREATE_REQUESTED:
    case UPDATE_REQUESTED:
    case GET_REQUESTED:
    case UPDATE_STATUS_REQUESTED:
    case FUNDRAISERS_COUNT_REQUESTED:
    case GET_PAGE_REQUESTED:
    case BULK_UPLOAD_REQUESTED:
      newState = entityHandlers.getRecordRequestedHandler(state, action);
      break;

    case CREATE_SUCCESS:
    case UPDATE_SUCCESS:
    case UPDATE_STATUS_SUCCESS:
    case FUNDRAISERS_COUNT_SUCCESS:
      newState = entityHandlers.getRecordSuccessHandler(state, action);
      break;

    case GET_SUCCESS: {
      newState = entityHandlers.getRecordSuccessHandler(state, action);
      const questionsBaseModel = get(newState, 'campaignRecord.data.data.event')
        ? get(newState, 'campaignRecord.data.data.event')
        : get(newState, 'campaignRecord.data.data');
      const questions = getQuestionsFromModel(
        questionsBaseModel,
        fieldCategoryKey.FUNDRAISING
      );
      newState = update.set(
        newState,
        `campaignRecord.data.data.${FORMBUILDER_REGISTRATION_PATH}`,
        questions
      );
      break;
    }

    case GET_PAGE_SUCCESS: {
      newState = entityHandlers.getRecordSuccessHandler(state, action);
      const oldQuestionsPath = `${generalFormBuilderPath}.${oldCustomFieldCategory.FUNDRAISING}`;
      const oldQuestions = get(
        newState,
        `record.data.data.${oldQuestionsPath}`
      );

      if (hasFormbuilderQuestions(oldQuestions)) {
        const modelWithNewQuestions = getDataWithQuestionFields(
          get(newState, 'record.data.data'),
          oldQuestionsPath
        );
        newState = update.set(
          newState,
          'record.data.data',
          modelWithNewQuestions
        );
      }

      const questionsBaseModelPath = get(newState, 'record.data.data.event')
        ? 'event'
        : 'campaign';
      const questions = getQuestionsFromModel(
        get(newState, `record.data.data.${questionsBaseModelPath}`),
        fieldCategoryKey.FUNDRAISING
      );
      const pageAnswers = get(newState, 'record.data.data.fields');
      const phoneNumber = get(newState, 'record.data.data.phoneNumber');
      const address = get(newState, 'record.data.data.address');

      const customFieldsWithAnswers = getCustomFieldsWithAnswers(
        questions,
        pageAnswers,
        phoneNumber,
        address
      );
      newState = update.set(
        newState,
        `record.data.data.${FORMBUILDER_REGISTRATION_PATH}`,
        customFieldsWithAnswers
      );

      break;
    }

    case CREATE_FAILURE:
    case GET_PAGE_FAILURE:
    case UPDATE_FAILURE:
    case GET_FAILURE:
    case UPDATE_STATUS_FAILURE:
    case FUNDRAISERS_COUNT_FAILURE:
      newState = entityHandlers.crudErrorHandler(state, action);
      break;

    case ERROR_CLEAR:
      newState = entityHandlers.crudClearErrorHandler(
        state,
        action.payload.key
      );
      break;

    case ON_VALUE_CHANGE:
      newState = update.set(state, action.payload.path, action.payload.value);
      break;

    case BULK_UPLOAD_SUCCESS:
      newState = getInitialStateForBulkUpload(state);
      newState = entityHandlers.getRecordSuccessHandler(newState, action);
      break;

    case BULK_UPLOAD_FAILURE:
      newState = getInitialStateForBulkUpload(state);
      newState = entityHandlers.crudErrorHandler(newState, action);
      break;

    case UPLOAD_SUCCESS:
      newState = update.set(state, action.payload.path, action.payload.data);
      break;

    default:
      return state;
  }

  return newState;
};

const getManagerOperation = (baseManager, editedManager) => {
  if (!editedManager) {
    return;
  }

  const editedManagerMail = get(editedManager, 'email');
  const baseManagerMail = get(baseManager, 'email');

  if (!editedManagerMail && baseManagerMail) {
    return fundraisingManagerAccountOperation.REMOVE_MANAGER_ACCOUNT;
  }

  if (
    (!baseManager && editedManagerMail) ||
    editedManagerMail !== baseManagerMail
  ) {
    return fundraisingManagerAccountOperation.INVITE;
  }

  if (editedManagerMail === baseManagerMail) {
    return fundraisingManagerAccountOperation.UNCHANGED;
  }
};

const getCustomInviteUrl = (data) => {
  if (data.orgUrl && data.campaignUrl && data.pageUrl) {
    return `${data.orgUrl}/invite?returnUrl=`;
  }
};

const getInviteUrlPartsFromCampaignRecord = (state, data) => {
  const campaignUrl = get(state, 'campaignPage.record.data.data.urlFull');
  const orgUrl = get(
    state,
    'campaignPage.record.data.data.organization.urlFull'
  );
  const pageUrl = data.urlPath;
  return { orgUrl, campaignUrl, pageUrl };
};

const getInviteUrlPartsFromPageRecord = (state, data) => {
  const campaignUrl = get(
    state,
    'campaignPage.record.data.data.campaign.urlFull'
  );
  const orgUrl = get(
    state,
    'campaignPage.record.data.data.organization.urlFull'
  );
  const pageUrl = data.urlPath;
  return { campaignUrl, orgUrl, pageUrl };
};

const getInitialStateForBulkUpload = (state) => {
  let newState = update.set(state, paths.IS_BULK_UPLOAD_MODEL_OPENED, false);
  newState = update.set(newState, paths.BULK_UPLOAD_DATA, null);

  return newState;
};

const createUpdatedAboutAchievement = async (entity, state, res) => {
  if (
    get(res.data, 'template.value.about')
      && !get(res.data, 'achievements', []).some(({ key }) => key === "tellAStory")
  ) {
    const achievementRes = await fetch(
      `${constants.baseApiHost}/api/v2/page/${entity}/${res.data.id}/achievement`,
      {
        method: "POST",
        headers: {
          ApiKey: constants.apikey,
          Authorization: `bearer ${state.session.key}`,
          'Content-Type': 'application/json',
          Verification: ""
        },
        body: JSON.stringify({
          quest: "tellAStory",
        }),
      }
    );
    
    if (achievementRes.ok) {
      return {
        ...res,
        data: {
          ...res.data,
          achievements: [
            ...res.data.achievements,
            { key: "tellAStory" },
          ],
        },
      };
    }
  }
};

export const pageActions = {
  getCampaignRecord: (id) =>
    entityHandlers.getRecord(
      'page/campaign',
      GET_REQUESTED,
      GET_SUCCESS,
      GET_FAILURE,
      id,
      'campaignRecord'
    ),

  bulkUpload: (uploadData) =>
    entityHandlers.createRecord(
      'page/campaign/bulk/fundraisers',
      BULK_UPLOAD_REQUESTED,
      BULK_UPLOAD_SUCCESS,
      BULK_UPLOAD_FAILURE,
      uploadData,
      (state, data) => {
        data = update.set(data, 'teamTemplate.key', templateKeys.TEAM_TEMPLATE);
        data = update.set(
          data,
          'fundraiserTemplate.key',
          templateKeys.FUNDRAISER_TEMPLATE
        );

        return data;
      },
      paths.BULK_UPLOAD_RECORD
    ),

  createFundraiserRecord: (record) =>
    entityHandlers.createRecord(
      'page/fundraiser',
      CREATE_REQUESTED,
      CREATE_SUCCESS,
      CREATE_FAILURE,
      record,
      (state, data) => {
        data = update.set(data, 'template.key', templateKeys.FUNDRAISER_PAGE);
        data = getDataWithQuestionFields(data, FORMBUILDER_REGISTRATION_PATH);

        if (data.manager) {
          data = update.set(
            data,
            'manager.operation',
            getManagerOperation(
              state.campaignPage.campaignRecord.data.data.manager,
              data.manager
            )
          );
          data = update.set(
            data,
            'manager.customInviteUrl',
            getCustomInviteUrl(getInviteUrlPartsFromCampaignRecord(state, data))
          );
        }

        const type = get(data, 'type');
        const defaultAbout = get(data, 'defaultFundraiserAbout');
        const about = get(data, 'template.value.about');
        if (
          aboutHandler[type].isAboutElementDefault(about, defaultAbout, data)
        ) {
          data = update.set(data, 'template.value.about', null);
        }

        const timeAndPlace = getTimeAndDateObject(data);
        data = update.set(data, 'timeAndPlace', timeAndPlace);
        data = update.set(data, 'template.value.timeAndPlace', null);

        return update(data, {
          organisationId: { $set: get(state, 'session.organization.id') }
        });
      },
      'saveRecord',
      "v2/",
      null,
      async (state, res) => await createUpdatedAboutAchievement("fundraiser", state, res),
    ),

  createTeamRecord: (record) =>
    entityHandlers.createRecord(
      'page/team',
      CREATE_REQUESTED,
      CREATE_SUCCESS,
      CREATE_FAILURE,
      record,
      (state, data) => {
        data = update.set(data, 'template.key', templateKeys.TEAM_PAGE);
        data = getDataWithQuestionFields(data, FORMBUILDER_REGISTRATION_PATH);

        if (data.manager) {
          data = update.set(
            data,
            'manager.operation',
            getManagerOperation(
              state.campaignPage.campaignRecord.data.data.manager,
              data.manager
            )
          );
          data = update.set(
            data,
            'manager.customInviteUrl',
            getCustomInviteUrl(getInviteUrlPartsFromCampaignRecord(state, data))
          );
        }

        const type = get(data, 'type');
        const defaultAbout = get(data, 'defaultTeamAbout');
        const about = get(data, 'template.value.about');
        if (
          aboutHandler[type].isAboutElementDefault(about, defaultAbout, data)
        ) {
          data = update.set(data, 'template.value.about', null);
        }
        
        const timeAndPlace = getTimeAndDateObject(data);
        data = update.set(data, 'timeAndPlace', timeAndPlace);
        data = update.set(data, 'template.value.timeAndPlace', null);

        return update(data, {
          organisationId: { $set: get(state, 'session.organization.id') }
        });
      },
      'saveRecord',
      "v2/",
      null,
      async (state, res) => await createUpdatedAboutAchievement("team", state, res),
    ),

  createCollectionRecord: (record) =>
    entityHandlers.createRecord(
      'page/collection',
      CREATE_REQUESTED,
      CREATE_SUCCESS,
      CREATE_FAILURE,
      record,
      (state, data) => {
        data = update.set(data, 'template.key', templateKeys.COLLECTION_PAGE);
        data = getDataWithQuestionFields(data, FORMBUILDER_REGISTRATION_PATH);

        data = update.set(data, 'isPublic', true);
        data = update.set(data, 'template.value.formBuilder', null);

        if (data.manager) {
          data = update.set(
            data,
            'manager.operation',
            getManagerOperation(
              state.campaignPage.campaignRecord.data.data.manager,
              data.manager
            )
          );
          data = update.set(
            data,
            'manager.customInviteUrl',
            getCustomInviteUrl(getInviteUrlPartsFromCampaignRecord(state, data))
          );
        }

        const type = get(data, 'type');
        const defaultAbout = get(data, 'defaultTeamAbout');
        const about = get(data, 'template.value.about');
        if (
          aboutHandler[type].isAboutElementDefault(about, defaultAbout, data)
        ) {
          data = update.set(data, 'template.value.about', null);
        }
        
        const timeAndPlace = getTimeAndDateObject(data);
        data = update.set(data, 'timeAndPlace', timeAndPlace);
        data = update.set(data, 'template.value.timeAndPlace', null);

        return data;
      },
      'saveRecord',
      "v2/",
      null,
      async (state, res) => await createUpdatedAboutAchievement("collection", state, res),
    ),

  updateFundraiserRecord: (data) => {
    return entityHandlers.updateRecord(
      'page/fundraiser',
      UPDATE_REQUESTED,
      UPDATE_SUCCESS,
      UPDATE_FAILURE,
      data.id,
      data,
      (state, data) => {
        if (data.manager) {
          data = update.set(
            data,
            'manager.operation',
            getManagerOperation(
              state.campaignPage.record.data.data.manager,
              data.manager
            )
          );
          data = update.set(
            data,
            'manager.customInviteUrl',
            getCustomInviteUrl(getInviteUrlPartsFromPageRecord(state, data))
          );
        }

        data = getDataWithQuestionFields(data, FORMBUILDER_REGISTRATION_PATH);

        const type = get(data, 'type');
        const defaultAbout = get(
          data,
          'campaign.template.value.defaultFundraiserAbout'
        );
        const about = get(data, 'template.value.about');
        if (
          aboutHandler[type].isAboutElementDefault(about, defaultAbout, data)
        ) {
          data = update.set(data, 'template.value.about', null);
        }
        
        const timeAndPlace = getTimeAndDateObject(data);
        data = update.set(data, 'timeAndPlace', timeAndPlace);
        data = update.set(data, 'template.value.timeAndPlace', null);

        return update(data, {
          organizationId: { $set: get(state, 'session.organization.id') }
        });
      },
      'saveRecord',
      async (state, res) => await createUpdatedAboutAchievement("fundraiser", state, res),
    );
  },

  updateTeamRecord: (data) => {
    return entityHandlers.updateRecord(
      'page/team',
      UPDATE_REQUESTED,
      UPDATE_SUCCESS,
      UPDATE_FAILURE,
      data.id,
      data,
      (state, data) => {
        if (data.manager) {
          data = update.set(
            data,
            'manager.operation',
            getManagerOperation(
              state.campaignPage.record.data.data.manager,
              data.manager
            )
          );
          data = update.set(
            data,
            'manager.customInviteUrl',
            getCustomInviteUrl(getInviteUrlPartsFromPageRecord(state, data))
          );
        }

        data = getDataWithQuestionFields(data, FORMBUILDER_REGISTRATION_PATH);

        const type = get(data, 'type');
        const defaultAbout = get(
          data,
          'campaign.template.value.defaultTeamAbout'
        );
        const about = get(data, 'template.value.about');
        if (
          aboutHandler[type].isAboutElementDefault(about, defaultAbout, data)
        ) {
          data = update.set(data, 'template.value.about', null);
        }
        
        const timeAndPlace = getTimeAndDateObject(data);
        data = update.set(data, 'timeAndPlace', timeAndPlace);
        data = update.set(data, 'template.value.timeAndPlace', null);

        return update(data, {
          organizationId: { $set: get(state, 'session.organization.id') }
        });
      },
      'saveRecord',
      async (state, res) => await createUpdatedAboutAchievement("team", state, res),
    );
  },

  updateCollectionRecord: (data) => {
    return entityHandlers.updateRecord(
      'page/collection',
      UPDATE_REQUESTED,
      UPDATE_SUCCESS,
      UPDATE_FAILURE,
      data.id,
      data,
      (state, data) => {
        if (data.manager) {
          data = update.set(
            data,
            'manager.operation',
            getManagerOperation(
              state.campaignPage.record.data.data.manager,
              data.manager
            )
          );
          data = update.set(
            data,
            'manager.customInviteUrl',
            getCustomInviteUrl(getInviteUrlPartsFromPageRecord(state, data))
          );
        }

        data = getDataWithQuestionFields(data, FORMBUILDER_REGISTRATION_PATH);

        const type = get(data, 'type');
        const defaultAbout = get(
          data,
          'campaign.template.value.defaultTeamAbout'
        );
        const about = get(data, 'template.value.about');
        if (
          aboutHandler[type].isAboutElementDefault(about, defaultAbout, data)
        ) {
          data = update.set(data, 'template.value.about', null);
        }

        const timeAndPlace = getTimeAndDateObject(data);

        data = update.set(data, 'timeAndPlace', timeAndPlace);
        data = update.set(data, 'template.value.timeAndPlace', null);

        return data;
      },
      'saveRecord',
      async (state, res) => await createUpdatedAboutAchievement("collection", state, res),
    );
  },

  getFundraiserRecord: (id) =>
    entityHandlers.getRecord(
      'page/fundraiser',
      GET_PAGE_REQUESTED,
      GET_PAGE_SUCCESS,
      GET_PAGE_FAILURE,
      id
    ),
  getTeamRecord: (id) =>
    entityHandlers.getRecord(
      'page/team',
      GET_PAGE_REQUESTED,
      GET_PAGE_SUCCESS,
      GET_PAGE_FAILURE,
      id
    ),
  getCollectionRecord: (id) =>
    entityHandlers.getRecord(
      'page/collection',
      GET_PAGE_REQUESTED,
      GET_PAGE_SUCCESS,
      GET_PAGE_FAILURE,
      id
    ),

  updateFundraiserStatus: (id, editableData) => {
    return entityHandlers.patchRecord(
      'page/fundraiser',
      UPDATE_STATUS_REQUESTED,
      UPDATE_STATUS_SUCCESS,
      UPDATE_STATUS_FAILURE,
      id,
      editableData,
      'statusRecord'
    );
  },
  updateTeamStatus: (id, editableData) => {
    return entityHandlers.patchRecord(
      'page/team',
      UPDATE_STATUS_REQUESTED,
      UPDATE_STATUS_SUCCESS,
      UPDATE_STATUS_FAILURE,
      id,
      editableData,
      'statusRecord'
    );
  },
  updateCollectionStatus: (id, editableData) => {
    return entityHandlers.patchRecord(
      'page/collection',
      UPDATE_STATUS_REQUESTED,
      UPDATE_STATUS_SUCCESS,
      UPDATE_STATUS_FAILURE,
      id,
      editableData,
      'statusRecord'
    );
  },

  clearRecord: () => ({ type: RECORD_CLEAR, payload: { key: 'record' } }),
  clearSaveRecord: () => ({
    type: RECORD_CLEAR,
    payload: { key: 'saveRecord' }
  }),
  clearRecordMessages: (recordKey) => ({
    type: ERROR_CLEAR,
    payload: { key: recordKey }
  }),
  onChange: (path, value) => ({
    type: ON_VALUE_CHANGE,
    payload: { path: path, value: value }
  }),

  getFundraisersCountByTeamId: (teamId) => {
    let aggregateUrl = 'page/fundraiser/aggregate'
      .addFieldName('id')
      .addFieldAggregate(listFilterAggregate.COUNT)
      .addFilter('teamId', listFilterOperator.EQUAL_TO, teamId, 0);

    return entityHandlers.getAggregate(
      FUNDRAISERS_COUNT_REQUESTED,
      FUNDRAISERS_COUNT_SUCCESS,
      FUNDRAISERS_COUNT_FAILURE,
      aggregateUrl,
      'fundraisersCountRecord'
    );
  }
};

export default campaignPageReducer;
