import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import get from 'lodash.get';
import { bindActionCreators } from 'redux';
import { Translate } from 'react-redux-i18n';

import * as templateBuilderActions from '../../modules/template-builder-ex';
import * as templateHelper from '../../components/template/templateHelper';

import TemplateBuilderContainerEx from '../../components/template/builder/indexEx';
import { getTicketTab } from './templateTabs/ticket';
import { getInvoiceInfromationTab } from './templateTabs/invoiceInfo';
import { getTicketContract, saleContract } from './ticketingContracts';
import { generalFormBuilderPath } from '../../constants/formBuilder';
import {
  ticketingValidationSimplyfiedModel,
  ticketingValidationFullModel
} from './validationModels';
import update from '../../helpers/update';
import { Common } from '../../components';
import { getMerchandiseTab } from './templateTabs/merchandise';
import { defaultDetails } from './constants';
import { campaignContract } from '../campaign/create/templates';
import { getCustomFieldsWithAnswers } from '../../helpers/formBuilderHelper';

class TicketingTemplateView extends Component {
  constructor(props) {
    super(props);

    this.required = {
      required: true
    };

    this.optional = {
      required: false
    };

    this.formBuilderAnswers = {
      formBuilderRequired: true,
      formBuilderAnswers: true
    };
  }

  getCampaignItem = (item) => {
    if (item.organization) {
      return {
        text: item.name,
        subtext: `${item.organization.name}`,
        value: item.id
      };
    } else {
      return {
        text: item.name,
        value: item.id
      };
    }
  };

  getCampaignRequest = () => {
    return {
      page: 1,
      pageSize: 75,
      resultType: 'Full',
      includeDataTemplate: true,
      orderBy: {
        key: 'name',
        direction: 'asc'
      },
      filters: [
        {
          key: 'status',
          operator: 'EqualTo',
          value: 1
        }
      ]
    };
  };

  // compareDate = (date1, date2) => {
  //     return moment(date1).unix() - moment(date2).unix();
  // }

  getTicketItem = (item) => {
    const ticketIndex = getTicketId(this.props);
    const ticketContract = getTicketContract(ticketIndex - 1);
    const selectedTicketId = get(
      this.props,
      `record.${ticketContract.ticketId}`
    );

    const isTicketDisabled = this.isTicketDisabled(item);
    if (isTicketDisabled && selectedTicketId !== item.id) {
      return null;
    }

    // if (item.availableUntil && this.compareDate(moment(item.availableUntil).format(config.DEFAULT_DATE_FORMAT), moment(new Date()).format(config.DEFAULT_DATE_FORMAT)) <= 0) {
    //     return null;
    // }

    // if (item.availableFrom && this.compareDate(moment(item.availableFrom).format(config.DEFAULT_DATE_FORMAT), moment(new Date()).format(config.DEFAULT_DATE_FORMAT)) >= 0) {
    //     return null;
    // }

    return {
      text: item.name,
      value: item.id
    };
  };

  getTicketsRequest = () => {
    const campaignId = get(this.props, 'record.campaignId');
    return {
      page: 1,
      pageSize: 75,
      resultType: 'Full',
      includeDataTemplate: true,
      orderBy: {
        key: 'name',
        direction: 'asc'
      },
      filters: [
        { key: 'campaignId', operator: 'EqualTo', value: campaignId },
        { key: 'enabled', operator: 'EqualTo', value: true }
      ]
    };
  };

  onChange = (path, value, item) => {
    this.props.setResultValue(path, value);
  };

  getQuestions = (campaignItem) => {
    const campaignQuestions = get(campaignItem, saleContract.formbuilder);
    return getCustomFieldsWithAnswers(
      campaignQuestions,
      this.props.donationQuestions,
      this.props.phoneNumber,
      this.props.address
    );
  };

  onCampaignChange = (path, value, item) => {
    this.onChange(saleContract.details, defaultDetails);

    const purchaseFundraisingIsMandatory = get(
      item,
      campaignContract.purchaseFundraisingIsMandatory
    );
    if (purchaseFundraisingIsMandatory) {
      this.onChange(saleContract.oneClickFundraising, true);
    }

    const questions = this.getQuestions(item);
    this.onChange(saleContract.formbuilder, questions);
    this.onChange(path, value, item);
  };

  onTicketChange = (path, value, item, index) => {
    const ticketContract = getTicketContract(index);
    const formBuilder = get(item, generalFormBuilderPath);
    this.onChange(ticketContract.formbuilder, formBuilder);
    this.onChange(path, value, item);
  };

  getValidation = () =>
    this.props.isEdit
      ? ticketingValidationSimplyfiedModel
      : ticketingValidationFullModel;

  getTickets = () =>
    get(this.props, 'record.details', [])
      .map((item, index) => ({ item, index }))
      .filter(({ item }) => item.ticket);

  getTicketPages = () =>
    this.getTickets().map(({ item, index }, number) =>
      getTicketTab({
        id: item.id || index,
        index,
        number,
        onChange: this.onChange,
        onTicketChange: this.onTicketChange,
        getCampaignRequest: this.getCampaignRequest,
        getCampaignItem: this.getCampaignItem,
        getTicketsRequest: this.getTicketsRequest,
        getTicketItem: this.getTicketItem,
        required: this.required,
        optional: this.optional,
        formValidation: this.getValidation(),
        formBuilderAnswers: this.formBuilderAnswers,
        currency: this.props.record.currency
      })
    );

  getMerchandise = () =>
    get(this.props, 'record.details', [])
      .map((item, index) => ({ item, index }))
      .filter(({ item }) => item.merchandiseSku);

  getMerchandisePages = () =>
    this.getMerchandise().map(({ item, index }, number) =>
      getMerchandiseTab({
        id: item.id || index,
        index,
        number,
        onChange: this.onChange,
        getCampaignRequest: this.getCampaignRequest,
        getCampaignItem: this.getCampaignItem,
        required: this.required,
        optional: this.optional,
        formValidation: this.getValidation(),
        formBuilderAnswers: this.formBuilderAnswers
      })
    );

  getInvoiceInformationPage = () => {
    return getInvoiceInfromationTab({
      onChange: this.onChange,
      onCampaignChange: this.onCampaignChange,
      getCampaignRequest: this.getCampaignRequest,
      getCampaignItem: this.getCampaignItem,
      required: this.required,
      optional: this.optional,
      formValidation: this.getValidation(),
      formBuilderAnswers: this.formBuilderAnswers,
      feeCovered: this.props.feeCovered,
      currency: this.props.record.currency
    });
  };

  addAnotherTicket = () => {
    const campaignId = get(this.props, 'record.campaignId');

    let tickets = get(this.props, 'record.details');

    tickets = update(tickets, {
      $push: [
        {
          campaignId: campaignId,
          quantity: 1,
          ticket: {}
        }
      ]
    });
    this.onChange('details', tickets);

    const index = tickets && tickets.length - 1;
    const ticketContract = getTicketContract(index);
    this.props.changeItemVisibility(ticketContract.campaignId, false, false);
    this.props.changeItemVisibility(ticketContract.ticketName, false, false);
    this.props.changeItemVisibility(ticketContract.amount, false, false);

    this.props.history.push(`ticket-${index}`);
  };

  isTicketDisabled = (ticket) => {
    const usedTickets = get(this.props, 'record.details');
    const quantity = get(ticket, 'inventory.quantity');

    if (!quantity && quantity !== 0) {
      return false;
    }

    if (quantity) {
      const sold = get(ticket, 'inventory.sold');
      const usedTicketsCount = usedTickets.filter(
        (usedTicket) => usedTicket.ticketId === ticket.id
      ).length;
      if (quantity - sold - usedTicketsCount > 0) {
        return false;
      }
    }

    return true;
  };

  isAddAnotherTicketDisabled = () => {
    const availableTickets = get(this.props, 'ticketsRecord.data.list');
    if (!availableTickets || !availableTickets.length) return false;

    for (let index = 0; index < availableTickets.length; index++) {
      const ticket = availableTickets[index];
      const isTicketDisabled = this.isTicketDisabled(ticket);
      if (!isTicketDisabled) {
        return false;
      }
    }
    return true;
  };

  customTemplateActionButton = (isDisabled, isLoading) => {
    return (
      <Common.Button
        key="add-another-ticket"
        id="add-another-ticket"
        primary
        disabled={this.isAddAnotherTicketDisabled() || isDisabled}
        loading={isLoading}
        style={{ minWidth: '100px' }}
        onClick={this.addAnotherTicket}
      >
        <Translate value={'ticketing.create.custom-template-button'} />
      </Common.Button>
    );
  };

  render() {
    const invoiceInformationPage = this.getInvoiceInformationPage();
    const ticketPages = this.getTicketPages();
    const merchandisePages = this.getMerchandisePages();

    return (
      <TemplateBuilderContainerEx
        className="margin-top-30"
        onSave={this.props.onSave}
        isLoading={this.props.isLoading}
        customTemplateActionButton={this.customTemplateActionButton}
      >
        {invoiceInformationPage}
        {ticketPages}
        {merchandisePages}
      </TemplateBuilderContainerEx>
    );
  }
}

const getTicketId = (props) => {
  const step = props.match.params.step;
  const pattern = /[0-9]+/;
  const result = step.match(pattern);
  return result ? result[0] : null;
};

const mapState = ({ ticketing, templateBuilderEx, autocomplete }, ownProps) => {
  const campaignQuestions = get(
    ticketing,
    `record.data.data.campaign.${saleContract.formbuilder}`
  );
  const ticketingAnswers = get(ticketing, `record.data.data.fields`);
  const phoneNumber = get(ticketing, `record.data.data.phoneNumber`);
  const address = get(ticketing, `record.data.data.address`);
  const ticketingQuestions = getCustomFieldsWithAnswers(
    campaignQuestions,
    ticketingAnswers,
    phoneNumber,
    address
  );
  const feeCovered = templateHelper.getValue(
    templateBuilderEx,
    saleContract.feeCovered
  );

  const ticketIndex = getTicketId(ownProps);
  const ticketContract = getTicketContract(ticketIndex);

  return {
    record: get(templateBuilderEx, 'data'),
    ticketsRecord: get(autocomplete, ticketContract.ticketId),
    isEdit: get(templateBuilderEx, 'isEdit'),
    ticketingQuestions,
    phoneNumber,
    address,
    feeCovered
  };
};

const mapDispatch = (dispatch) => {
  return bindActionCreators(templateBuilderActions, dispatch);
};

const TicketingTemplate = withRouter(
  connect(mapState, mapDispatch)(TicketingTemplateView)
);
export default TicketingTemplate;
