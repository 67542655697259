import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';

import { Button, Checkbox } from 'semantic-ui-react';
import get from 'lodash.get';

import {
  donationI18Keys,
  donationSettings
} from './constants/donationSettings';

import currencyHelper from '../../../helpers/currencyHelper';

class DonationHeader extends Component {
  handleEditClick = () => {
    if (this.props.donation) {
      this.props.history.push(
        `/donations/edit/${this.props.donation.id}/donation-detail`
      );
    }
  };

  render() {
    const { financials, type, amount: donationAmount, currency: donationCurrency, organization } = this.props.donation;
    const { isShowEmptyFields, displayOrgCurrency } = this.props;

    const exchangeRate = get(financials, "exchangeRate");
    const amount = displayOrgCurrency && exchangeRate
      ? donationAmount * exchangeRate
      : donationAmount;
    const currency = displayOrgCurrency ? get(organization, "currency") : donationCurrency;

    const amountString = currencyHelper.formatCurrency(amount, currency);
    const refunded = get(financials, "refunded");
    const originalCurrencyRefundedAmount = get(financials, "refundedAmount");
    const refundedAmount = displayOrgCurrency && exchangeRate && originalCurrencyRefundedAmount
      ? originalCurrencyRefundedAmount * exchangeRate
      : originalCurrencyRefundedAmount;
    const isPartialRefund = type == "online" && refunded && refundedAmount > 0 && refundedAmount < amount;
    const typeOfDonation = type || "N/A";

    const donationPrimaryStatusBackgroundColor =
      financials &&
      donationSettings[typeOfDonation].getPrimaryStatusBackgroundColor(
        refunded, isPartialRefund
      );
    const donationSecondaryStatusBackgroundColor =
      financials &&
      donationSettings[typeOfDonation].getSecondaryStatusBackgroundColor();

    const donationPrimaryStatusValue =
      financials &&
      donationSettings[typeOfDonation].getPrimaryStatusValue(refunded, isPartialRefund);
    const donationSecondaryStatusValue =
      financials && donationSettings[typeOfDonation].getSecondaryStatusValue();

    return (
      <div className="donation-header">
        <div>{I18n.t(`${donationI18Keys.donationHeaderI18Key}.donation`)}</div>
        <div className="donation-header-content">
          <div className="first-section-block">
            <h1>{`${currency} ${amountString}`}</h1>
            <div className="status-type">
              <span
                style={{ background: donationPrimaryStatusBackgroundColor }}
              >
                {donationPrimaryStatusValue}
              </span>
            </div>
            {donationSecondaryStatusValue && (
              <div className="status-type">
                <span
                  style={{ background: donationSecondaryStatusBackgroundColor }}
                >
                  {donationSecondaryStatusValue}
                </span>
              </div>
            )}
          </div>
          <div className="second-section-block">
            <div className="empty-fields-link">
              <p
                className="Links"
                onClick={this.props.changeVisibilityEmptyFields}
              >
                {isShowEmptyFields
                  ? I18n.t(
                      `${donationI18Keys.donationHeaderI18Key}.hide-empty-fields-link`
                    )
                  : I18n.t(
                      `${donationI18Keys.donationHeaderI18Key}.show-empty-fields-link`
                    )}
              </p>
            </div>
            {donationCurrency !== get(organization, "currency")
              && get(financials, "exchangeRate") && (
              <div className="empty-fields-link">
                <p
                  className="Links"
                  onClick={this.props.onToggleCurrencyDisplay}
                >
                  {I18n.t(
                    `${donationI18Keys.donationHeaderI18Key}.show-different-currency-link`,
                    {
                      currency: displayOrgCurrency ? donationCurrency : get(organization, "currency"),
                    }
                  )}
                </p>
              </div>
            )}
            <Button
              primary
              style={{ float: 'right', minWidth: 100 }}
              onClick={this.handleEditClick}
            >
              {I18n.t(
                `${donationI18Keys.donationHeaderI18Key}.show-edit-button`
              )}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(DonationHeader);
