import { TemplateCurrency } from '../../../components/template/templateCurrency';
import React from 'react';
import { TemplatePage } from '../../../components/common/pager';
import { paths } from '../constants/template';
import { TemplateInput } from '../../../components/template/templateInput';
import { TemplateToggle } from '../../../components/template/templateToggle';
import { TemplateCheckbox } from '../../../components/template/templateCheckbox';
import { TemplateHorizontalRule } from '../../../components/template/templateHorizontalRule';
import { formBuilderKeys } from '../../campaign/constants';
import { I18n } from 'react-redux-i18n';
import { getFromBuilderInitialData } from '../../../helpers/formBuilderHelper';
import TemplateFormBuilder from '../../../components/template/templateFromBuilder';
import { TemplateHeader } from '../../../components/template/templateHeader';

export const getFundraisingTab = (props) => {
  const {
    onChange,
    onFundraiserEnabledChange,
    onTeamEnabledChange,
    formBuilderQuestionsValidation,
    targetValidation,
    required,
    getTargetValidation,
    currency
  } = props;

  return (
    <TemplatePage
      id="fundraising"
      i18nKey="event.template.page.fundraising.title"
    >
      <TemplateHeader
        as="h3"
        label="event.template.page.fundraising.personal-pages-header"
      />

      <TemplateCheckbox
        id="fundraisingEventFundraisersEnabled"
        path={paths.FUNDRAISERS_ENABLED}
        inputLabel="event.template.page.fundraising.fundraisers-enabled.label"
        onChange={onFundraiserEnabledChange}
      />

      <TemplateToggle
        path={paths.FUNDRAISERS_ENABLED}
        className={'padding-left-style'}
        validation={getTargetValidation(
          paths.FUNDRAISERS_ENABLED,
          paths.ALLOW_FUNDRAISER_SELF_SIGN_UP,
          paths.FUNDRAISING_TARGET
        )}
      >
        <TemplateCheckbox
          id="fundraisingEventFundraiserAllowSelfSignUp"
          path={paths.ALLOW_FUNDRAISER_SELF_SIGN_UP}
          inputLabel="event.template.page.fundraising.fundraiser-self-sing-up.label"
          onChange={onChange}
        />

        <TemplateToggle
          path={paths.FUNDRAISERS_ENABLED}
          className={'padding-left-style'}
        >
          <TemplateCurrency
            id="fundraisingEventFundraisingTarget"
            path={paths.FUNDRAISING_TARGET}
            label="event.template.page.fundraising.fundraising-target.label"
            subtextPosition="bottom"
            validation={targetValidation}
            onChange={onChange}
            currency={currency}
          />

          <TemplateInput
            id="fundraisingEventFundraisingAlias"
            path={paths.FUNDRAISING_ALIAS}
            label="event.template.page.fundraising.fundraising-alias.label"
            subtextPosition="bottom"
            onChange={onChange}
          />
        </TemplateToggle>
      </TemplateToggle>

      <TemplateCheckbox
        id="fundraisingEventTeamsEnabled"
        path={paths.TEAMS_ENABLED}
        inputLabel="event.template.page.fundraising.teams-enabled.label"
        onChange={onTeamEnabledChange}
      />

      <TemplateToggle
        path={paths.TEAMS_ENABLED}
        className={'padding-left-style'}
        validation={getTargetValidation(
          paths.TEAMS_ENABLED,
          paths.ALLOW_TEAM_SELF_SIGN_UP,
          paths.TEAM_TARGET
        )}
      >
        <TemplateCheckbox
          id="fundraisingEventTeamAllowSelfSignUp"
          path={paths.ALLOW_TEAM_SELF_SIGN_UP}
          inputLabel="event.template.page.fundraising.team-self-sing-up.label"
          onChange={onChange}
        />

        <TemplateToggle
          path={paths.TEAMS_ENABLED}
          className={'padding-left-style'}
        >
          <TemplateCurrency
            id="fundraisingEventTeamTarget"
            path={paths.TEAM_TARGET}
            label="event.template.page.fundraising.team-target.label"
            subtextPosition="bottom"
            validation={targetValidation}
            onChange={onChange}
            currency={currency}
          />

          <TemplateInput
            id="fundraisingEventTeamAlias"
            path={paths.TEAM_ALIAS}
            label="event.template.page.fundraising.team-alias.label"
            subtextPosition="bottom"
            onChange={onChange}
          />
        </TemplateToggle>
      </TemplateToggle>

      <TemplateHorizontalRule />

      <TemplateInput
        id="fundraisingEventSignUpText"
        path={paths.SIGNUP_TEXT}
        label="event.template.page.fundraising.sign-up.label"
        onChange={onChange}
        validation={required}
      />

      <TemplateHeader
        as="h5"
        label="event.template.page.fundraising.data-capture.label"
      />

      <TemplateFormBuilder
        formBuilderKey={formBuilderKeys.EVENT_FUNDRAISING}
        path={paths.FUNDRAISING_QUESTIONS}
        initialData={getFromBuilderInitialData(
          formBuilderKeys.EVENT_FUNDRAISING
        )}
        onChange={onChange}
        validation={formBuilderQuestionsValidation}
        subtext={I18n.t('event.template.page.fundraising.data-capture.subtext')}
      />
    </TemplatePage>
  );
};
