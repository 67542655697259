import { customFieldCategory } from '../../../constants/formBuilder';

export const paths = {
  NAME: 'name',
  URL_PATH: 'urlPath',
  YEAR: 'timeAndPlace.year',
  START_DATE: 'timeAndPlace.date.start',
  END_DATE: 'timeAndPlace.date.end',
  ORGANIZATION_ID: 'organizationId',
  EVENT_ORGANIZER_ID: 'eventOrganizerId',
  ORGANIZATION_FEE: 'organizationFee',
  FUNDRAISER_FEE: 'fundraiserFee',
  FUNDRAISING_EVENT_TARGET: 'targetAmount',
  EVENT_ABOUT: 'template.value.about',
  CAMPAIGN_ABOUT: 'template.value.defaultCampaignAbout',
  TAGLINE: 'template.value.tagline',
  HERO_IMAGE: 'template.value.heroImagePath',
  HERO_VIDEO: 'template.value.heroVideoUrl',
  DISPLAY_IMAGE: 'mainImagePath',
  PRIMARY_COLOR: 'template.value.style.primaryColor',
  SECONDARY_COLOR: 'template.value.style.secondaryColor',
  INVERT_PRIMARY_COLOR: 'template.value.style.invertPrimaryTextColor',
  INVERT_SECONDARY_COLOR: 'template.value.style.invertSecondaryTextColor',
  HAS_TIERS: 'template.value.hasTiers',
  EXTERNAL_SYSTEM: 'external.system',
  EXTERNAL_SYSTEM_ID: 'external.id',
  EXTERNAL_SYSTEP_API_KEY: 'external.apiKey',
  DISPLAY_FULL_AMOUNT_PRECISION:
    'template.value.customSetup.displayFullAmountPrecision',
  TICKET_BUTTON_SETTINGS_PARAMETER_NAME:
    'template.value.customSetup.ticketButtonSettings.parameterName',
  TICKET_BUTTON_SETTINGS_PARAMETER_TYPE:
    'template.value.customSetup.ticketButtonSettings.parameterType',
  TICKET_BUTTON_SETTINGS_USE_URL:
    'template.value.customSetup.ticketButtonSettings.useUrl',
  TICKET_BUTTON_SETTINGS_USE_ORG_URL:
    'template.value.customSetup.ticketButtonSettings.useOrgUrl',
  ORGANIZATIONS: 'organizations',
  FUNDRAISERS_ENABLED: 'fundraisers.enabled',
  ALLOW_FUNDRAISER_SELF_SIGN_UP: 'fundraisers.allowSelfSignUp',
  ALLOW_TIME_AND_PLACE: 'template.value.timeAndPlace.enabled',
  FUNDRAISING_TARGET: 'fundraisers.defaultTargetAmount',
  FUNDRAISING_ALIAS: 'template.value.fundraisers.label',
  TEAMS_ENABLED: 'teams.enabled',
  ALLOW_TEAM_SELF_SIGN_UP: 'teams.allowSelfSignUp',
  TEAM_TARGET: 'teams.defaultTargetAmount',
  TEAM_ALIAS: 'template.value.teams.label',
  SIGNUP_TEXT: 'template.value.registrationPageText',
  FUNDRAISING_QUESTIONS: `template.value.formBuilder.${customFieldCategory.FUNDRAISING}`,
  DONATION_QUESTIONS: `template.value.formBuilder.${customFieldCategory.DONATION}`,
  DEFAULT_AMOUNTS: 'template.value.donationSetup.defaultAmounts',
  DONOR_ALIAS: 'template.value.donationSetup.label',
  DONATION_TEXT: 'template.value.donationSetup.donationPageText',
  THANK_YOU_MESSAGE: 'template.value.donationSetup.thankYouMessage',
  THANK_YOU_MEDIA_TYPE: 'template.value.donationSetup.selectedMediaType',
  THANK_YOU_IMAGE: 'template.value.donationSetup.thankYouImageUrl',
  THANK_YOU_VIDEO: 'template.value.donationSetup.thankYouVideoUrl',
  DEFAULT_TEXT_FOR_FUNDRAISING_PAGES: 'template.value.defaultFundraiserAbout',
  DEFAULT_TEXT_FOR_TEAM_PAGES: 'template.value.defaultTeamAbout',
  INCLUDE_FEE: 'donationSetup.allowCoverFee',
  ALLOW_RECURRING_GIVING: 'donationSetup.allowRecurringGiving',
  EVENT_STATUS: 'status',
  TICKETING: 'template.value.ticketingSetup.simpleTicketingEnabled',
  PURCHASE_TICKET_URL: 'template.value.ticketingSetup.purchaseTicketUrl',
  TICKET_BUTTON_TEXT: 'template.value.customText.ticketButtonText',
  ALLOW_MICROBLOGS: 'microblogs.enabled',
  ALLOW_ACHIEVEMETNS: 'achievements.enabled',
  MARKETING_WEBSITE: 'template.value.marketingWebsite',
  TAGS: 'tags',
  CUSTOM_ACTIVITIES_ENABLED: 'customActivities.enabled',
  CUSTOM_ACTIVITIES_VALUE1_NAME: 'customActivities.value1.name',
  CUSTOM_ACTIVITIES_VALUE1_CAMPAIGN_TARGET_FIELD:
    'customActivities.value1.target',
  CUSTOM_ACTIVITIES_VALUE1_FUNDRAISER_DEFAULT_TARGER_FIELD:
    'customActivities.value1.fundraiserDefaultTarget',
  CUSTOM_ACTIVITIES_VALUE1_TEAM_DEFAULT_TARGET_FIELD:
    'customActivities.value1.teamDefaultTarget',
  CUSTOM_ACTIVITIES_VALUE1_UNIT: 'customActivities.value1.unitName',
  CUSTOM_ACTIVITIES_VALUE1_SVG:
    'template.value.customActivitiesSetup.value1.svg',
  CUSTOM_ACTIVITIES_VALUE1_THERMOMETER_ENABLED:
    'template.value.customActivitiesSetup.value1.enableThermometer',
  CUSTOM_ACTIVITIES_VALUE1_LEADERBOARD_ENABLED:
    'template.value.customActivitiesSetup.value1.enableLeaderboard',
  TIMEZONE: 'timezone',
  RAISED_TOTAL_TYPE: 'generalSettings.raisedTotalType',
  TIERS: {
    name: (i) => `template.value.tiers.${i}.name`,
    target: (i) => `template.value.tiers.${i}.target`,
    enabled: (i) => `template.value.tiers.${i}.enabled`,
    regoFlowVisible: (i) =>
      `template.value.tiers.${i}.options.organizationListing.externalRegistrationFlow.visible`,
    searchable: (i) =>
      `template.value.tiers.${i}.options.organizationListing.externalRegistrationFlow.searchable`,
    logoBehaviour: (i) =>
      `template.value.tiers.${i}.options.organizationListing.externalRegistrationFlow.logoBehaviour`,
    randomOrder: (i) =>
      `template.value.tiers.${i}.options.organizationListing.externalRegistrationFlow.randomOrder`,
    displayAboutText: (i) =>
      `template.value.tiers.${i}.options.organizationListing.externalRegistrationFlow.displayAboutText`,
    allowDonations: (i) =>
      `template.value.tiers.${i}.options.organizationListing.externalRegistrationFlow.allowDonations`
  },
  SPECIAL_EFFECTS_CONFETTI_ENABLED: 'template.value.specialEffects.confetti.enabled',  
  EVENT_CURRENCY: 'currency',
};

export const TIER_COUNT = 4;

export const LOGO_OPTIONS = [
  {
    text: 'No logo (RegisterNow only)',
    value: 'none'
  },
  {
    text: 'Small logo',
    value: 'small'
  },
  {
    text: 'Medium logo',
    value: 'medium'
  },
  {
    text: 'Large logo',
    value: 'large'
  },
  {
    text: 'Searchable dropdown (Active only)',
    value: 'dropdown'
  },
  {
    text: 'Text list (Active only)',
    value: 'list'
  }
];

export const EXTERNAL_REGO_FLOW_OPTIONS = [
  'active',
  'registerNow',
  'raceRoster'
];
