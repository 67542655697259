import { accountsUrl } from '../pages/accounts/constants';
import get from 'lodash.get';
import { pageTypesStrings } from '../constants/pageTypes';

export const CONTEXT_MENU_KEYS = {
  //-------------------------CampaignTable
  CAMPAIGN_MENU: {
    VIEW_DASHBOARD: {
      key: 'viewDashboard',
      getUrl(item: { id: any }, _params: any) {
        return `/campaigns/dashboard/${item.id}`;
      }
    },
    EDIT_CAMPAIGN: {
      key: 'editCampaign',
      getUrl(item: { id: any }, _params: any) {
        return `/campaigns/edit/${item.id}/setup`;
      }
    },
    VIEW_PUBLIC_PAGE: {
      key: 'viewPublicPage',
      getUrl(item: { urlFull: any }, _params: any) {
        return item.urlFull;
      },
      target: '_blank'
    },
    VIEW_SALES: {
      key: 'viewSales',
      getUrl(item: { id: any }, _params: any) {
        return `/campaigns/view/sales/${item.id}`;
      }
    },
    VIEW_TICKETS: {
      key: 'viewTickets',
      getUrl(item: { id: any }, _params: any) {
        return `/campaigns/view/tickets/${item.id}`;
      }
    },
    VIEW_MERCHANDISE: {
      key: 'viewMerchandise',
      getUrl(item: { id: any }, _params: any) {
        return `/campaigns/view/merchandise/${item.id}`;
      }
    },
    VIEW_DONATIONS: {
      key: 'viewDonations',
      getUrl(item: { id: any }, _params: any) {
        return `/campaigns/view/donations/${item.id}`;
      }
    },
    VIEW_FUNDRAISERS: {
      key: 'viewFundraisers',
      getUrl(item: { id: any }, _params: any) {
        return `/campaigns/view/fundraisers/${item.id}`;
      }
    },
    CLONE_CAMPAIGN: {
      key: 'cloneCampaign',
      getUrl(item: { id: any }, _params: any) {
        return `/campaigns/clone/${item.id}/setup`;
      }
    }
  },

  //-------------------------AccountDetailTable
  ACCOUNT_DETAILS: {
    VIEW_DONATION: {
      key: 'donations_view',
      getUrl(item: { id: any }, _params: any) {
        return `/donations/view/${item.id}`;
      }
    },
    EDIT_DONATION: {
      key: 'account_detail_editDonation',
      getUrl(item: { id: any }, _params: any) {
        return `/donations/edit/${item.id}/donation-detail`;
      }
    },
    EDIT_PAGE: {
      key: 'account_detail_editPage',
      getUrl(
        item: { type: any; fundraiserId: any; teamId: any; collectionId: any },
        _params: any
      ) {
        switch (item.type) {
          case pageTypesStrings.FUNDRAISER:
            return `/campaigns/fundraiser/edit/${item.fundraiserId}/page-detail`;
          case pageTypesStrings.TEAM:
            return `/campaigns/team/edit/${item.teamId}/page-detail`;
          case pageTypesStrings.COLLECTION:
            return `/campaigns/collection/edit/${item.collectionId}/page-detail`;
        }
      }
    },
    NAVIGATE_TO_PUBLIC: {
      key: 'account_detail_navigateToPublicSite',
      getUrl(item: { urlFull: any }, _params: any) {
        return item.urlFull;
      },
      target: '_blank'
    },
    VIEW_PAGE_DONATIONS: {
      key: 'account_detail_viewPageDonations',
      getUrl(
        item: {
          fundraiser: any;
          campaignId: any;
          fundraiserId: any;
          teamId: any;
        },
        _params: any
      ) {
        if (item.fundraiser) {
          return `/campaigns/${item.campaignId}/fundraiser/view/donations/${item.fundraiserId}`;
        } else {
          return `/campaigns/${item.campaignId}/team/view/donations/${item.teamId}`;
        }
      }
    },
    VIEW_PAGE_FUNDRAISERS: {
      key: 'account_detail_viewPageFundraisers',
      getUrl(item: { campaignId: any; teamId: any }, _params: any) {
        return `/campaigns/${item.campaignId}/team/view/fundraisers/${item.teamId}`;
      }
    },
    IMPERSONATE_FUNDRAISER: {
      key: 'account_detail_impersonateFundraiser'
    },
    REFUND_DONATION: {
      key: 'account_detail_refundDonation'
    },
    DELETE_DONATION: {
      key: 'account_detail_deleteDonation'
    },
    REISSUE_RECEIPT: {
      key: 'account_detail_reissueReceipt'
    }
  },

  //-------------------------AccountTable
  ACCOUNT: {
    VIEW_PAGE: {
      key: 'account_view',
      getUrl(item: { id: any }, params: { selectedView: string | number }) {
        const view = get(accountsUrl[params.selectedView], 'view');
        return `/accounts/detail/${item.id}/${view}`;
      }
    },
    EDIT_PAGE: {
      key: 'account_edit',
      getUrl(item: { id: any }, _params: any) {
        return `/accounts/settings/${item.id}`;
      }
    },
    IMPERSONATE: {
      key: 'account_impersonate'
    }
  },

  //-------------------------DashboardTable
  DASHBOARD: {
    VIEW_DONATION: {
      key: 'donations_view',
      getUrl(item: { id: any }, _params: any) {
        return `/donations/view/${item.id}`;
      }
    },
    EDIT_DONATION: {
      key: 'dashboard_editDonation',
      getUrl(item: { id: any }, _params: any) {
        return `/donations/edit/${item.id}/donation-detail`;
      }
    },
    REFUND_DONATION: {
      key: 'dashboard_refundDonation'
    },
    DELETE_DONATION: {
      key: 'dashboard_deleteDonation'
    },
    REISSUE_RECEIPT: {
      key: 'dashboard_reissueReceipt'
    },
    EDIT_PAGE: {
      key: 'dashboard_editPage',
      getUrl(
        item: { type: any; fundraiserId: any; teamId: any; collectionId: any },
        _params: any
      ) {
        switch (item.type) {
          case pageTypesStrings.FUNDRAISER:
            return `/campaigns/fundraiser/edit/${item.fundraiserId}/page-detail`;
          case pageTypesStrings.TEAM:
            return `/campaigns/team/edit/${item.teamId}/page-detail`;
          case pageTypesStrings.COLLECTION:
            return `/campaigns/collection/edit/${item.collectionId}/page-detail`;
        }
      }
    },
    VIEW_PUBLIC: {
      key: 'dashboard_navigateToPublicSite',
      getUrl(item: { urlFull: any }, _params: any) {
        return item.urlFull;
      },
      target: '_blank'
    },
    VIEW_DONATIONS: {
      key: 'dashboard_viewPageDonations',
      getUrl(
        item: {
          type: any;
          campaignId: any;
          fundraiserId: any;
          teamId: any;
          collectionId: any;
        },
        _params: any
      ) {
        switch (item.type) {
          case pageTypesStrings.FUNDRAISER:
            return `/campaigns/${item.campaignId}/fundraiser/view/donations/${item.fundraiserId}`;
          case pageTypesStrings.TEAM:
            return `/campaigns/${item.campaignId}/team/view/donations/${item.teamId}`;
          case pageTypesStrings.COLLECTION:
            return `/campaigns/${item.campaignId}/collection/view/donations/${item.collectionId}`;
        }
      }
    },
    VIEW_FUNDRAISERS: {
      key: 'dashboard_viewPageFundraisers',
      getUrl(
        item: { type: any; campaignId: any; teamId: any; collectionId: any },
        _params: any
      ) {
        switch (item.type) {
          case pageTypesStrings.TEAM:
            return `/campaigns/${item.campaignId}/team/view/fundraisers/${item.teamId}`;
          case pageTypesStrings.COLLECTION:
            return `/campaigns/${item.campaignId}/collection/view/fundraisers/${item.collectionId}`;
        }
      }
    },
    EDIT_SALE: {
      key: 'dashboard_editSale',
      getUrl(item: { id: any }, _params: any) {
        return `/sales/edit/${item.id}/invoice-information`;
      }
    },
    REFUND_SALE: {
      key: 'dashboard_refundSale'
    },
    REISSUE_INVOICE: {
      key: 'dashboard_reissueInvoice'
    }
  },

  //-------------------------CampaignDetailsTable
  CAMPAIGN_DETAIL: {
    VIEW_DONATION: {
      key: 'donations_view',
      getUrl(item: { id: any }, _params: any) {
        return `/donations/view/${item.id}`;
      }
    },
    EDIT_DONATION: {
      key: 'campaign_detail_editDonation',
      getUrl(item: { id: any }, _params: any) {
        return `/donations/edit/${item.id}/donation-detail`;
      }
    },
    EDIT_SALE: {
      key: 'campaign_detail_editSale',
      getUrl(item: { id: any }, _params: any) {
        return `/sales/edit/${item.id}/invoice-information`;
      }
    },
    EDIT_TICKET: {
      key: 'campaign_detail_editTicket',
      getUrl(item: { sale: { id: any }; type: any; id: any }, _params: any) {
        return `/sales/edit/${item.sale.id}/${item.type}-${item.id}`;
      }
    },
    EDIT_MERCHANDISE: {
      key: 'campaign_detail_editMerchandise',
      getUrl(item: { sale: { id: any }; type: any; id: any }, _params: any) {
        return `/sales/edit/${item.sale.id}/${item.type}-${item.id}`;
      }
    },
    TRANSFER_DONATION: {
      key: 'campaign_detail_transferDonation',
      getUrl(item: { id: any }, _params: any) {
        return `/donation/${item.id}/transfer/setup`;
      }
    },
    REFUND_DONATION: {
      key: 'campaign_detail_refundDonation'
    },
    DELETE_DONATION: {
      key: 'campaign_detail_deleteDonation'
    },
    REFUND_SALE: {
      key: 'campaign_detail_refundSale'
    },
    REFUND_TICKET: {
      key: 'campaign_detail_refundTicket'
    },
    REISSUE_RECEIPT: {
      key: 'campaign_detail_reissueReceipt'
    },
    REISSUE_INVOICE: {
      key: 'campaign_detail_reissueInvoice'
    },
    REISSUE_TICKET: {
      key: 'campaign_detail_reissueTicket'
    },
    EDIT_PAGE: {
      key: 'campaign_detail_editPage',
      getUrl(
        item: { type: any; fundraiserId: any; teamId: any; collectionId: any },
        _params: any
      ) {
        switch (item.type) {
          case pageTypesStrings.FUNDRAISER:
            return `/campaigns/fundraiser/edit/${item.fundraiserId}/page-detail`;
          case pageTypesStrings.TEAM:
            return `/campaigns/team/edit/${item.teamId}/page-detail`;
          case pageTypesStrings.COLLECTION:
            return `/campaigns/collection/edit/${item.collectionId}/page-detail`;
        }
      }
    },
    VIEW_PUBLIC: {
      key: 'campaign_detail_navigateToPublicSite',
      getUrl(item: { urlFull: any }, _params: any) {
        return item.urlFull;
      },
      target: '_blank'
    },
    VIEW_TICKETS: {
      key: 'campaign_detail_viewPageTickets',
      getUrl(_item: any, _params: any) {
        //TODO
        return '';
      }
    },
    VIEW_SALE: {
      key: 'campaign_detail_viewPageSale',
      getUrl(item: { id: any }, _params: any) {
        return `/sales/view/${item.id}`;
      }
    },
    VIEW_DONATIONS: {
      key: 'campaign_detail_viewPageDonations',
      getUrl(
        item: {
          type: any;
          campaignId: any;
          fundraiserId: any;
          teamId: any;
          collectionId: any;
        },
        _params: any
      ) {
        switch (item.type) {
          case pageTypesStrings.FUNDRAISER:
            return `/campaigns/${item.campaignId}/fundraiser/view/donations/${item.fundraiserId}`;
          case pageTypesStrings.TEAM:
            return `/campaigns/${item.campaignId}/team/view/donations/${item.teamId}`;
          case pageTypesStrings.COLLECTION:
            return `/campaigns/${item.campaignId}/collection/view/donations/${item.collectionId}`;
        }
      }
    },
    VIEW_FUNDRAISERS: {
      key: 'campaign_detail_viewPageFundraisers',
      getUrl(
        item: { type: any; campaignId: any; teamId: any; collectionId: any },
        _params: any
      ) {
        switch (item.type) {
          case pageTypesStrings.TEAM:
            return `/campaigns/${item.campaignId}/team/view/fundraisers/${item.teamId}`;
          case pageTypesStrings.COLLECTION:
            return `/campaigns/${item.campaignId}/collection/view/fundraisers/${item.collectionId}`;
        }
      }
    }
  },
  //-------------------------FundraisersTable
  FUNDRAISERS: {
    EDIT_PAGE: {
      key: 'fundraiser_detail_editPage',
      getUrl(
        item: { type: any; fundraiserId: any; teamId: any; collectionId: any },
        _params: any
      ) {
        switch (item.type) {
          case pageTypesStrings.FUNDRAISER:
            return `/fundraisers/fundraiser/edit/${item.fundraiserId}/page-detail`;
          case pageTypesStrings.TEAM:
            return `/fundraisers/team/edit/${item.teamId}/page-detail`;
          case pageTypesStrings.COLLECTION:
            return `/fundraisers/collection/edit/${item.collectionId}/page-detail`;
        }
      }
    },
    VIEW_PUBLIC: {
      key: 'fundraiser_detail_navigateToPublicSite',
      getUrl(item: { urlFull: any }, _params: any) {
        return item.urlFull;
      },
      target: '_blank'
    },
    VIEW_DONATIONS: {
      key: 'fundraiser_detail_viewPageDonations',
      getUrl(
        item: {
          type: any;
          campaignId: any;
          fundraiserId: any;
          teamId: any;
          collectionId: any;
        },
        _params: any
      ) {
        switch (item.type) {
          case pageTypesStrings.FUNDRAISER:
            return `/campaigns/${item.campaignId}/fundraiser/view/donations/${item.fundraiserId}`;
          case pageTypesStrings.TEAM:
            return `/campaigns/${item.campaignId}/team/view/donations/${item.teamId}`;
          case pageTypesStrings.COLLECTION:
            return `/campaigns/${item.campaignId}/collection/view/donations/${item.collectionId}`;
        }
      }
    },
    VIEW_FUNDRAISERS: {
      key: 'fundraiser_detail_viewPageFundraisers',
      getUrl(
        item: { type: any; campaignId: any; teamId: any; collectionId: any },
        _params: any
      ) {
        switch (item.type) {
          case pageTypesStrings.TEAM:
            return `/campaigns/${item.campaignId}/team/view/fundraisers/${item.teamId}`;
          case pageTypesStrings.COLLECTION:
            return `/campaigns/${item.campaignId}/collection/view/fundraisers/${item.collectionId}`;
        }
      }
    }
  },

  //-------------------------DonationsTable
  DONATIONS: {
    VIEW_DONATION: {
      key: 'donations_view',
      getUrl(item: { id: any }, _params: any) {
        return `/donations/view/${item.id}`;
      }
    },
    EDIT_DONATION: {
      key: 'donations_editDonation',
      getUrl(item: { id: any }, _params: any) {
        return `/donations/edit/${item.id}/donation-detail`;
      }
    },
    REFUND_DONATION: {
      key: 'donations_refundDonation'
    },
    DELETE_DONATION: {
      key: 'donations_deleteDonation'
    },
    REISSUE_RECEIPT: {
      key: 'donations_reissueReceipt'
    },
    TRANSFER_DONATION: {
      key: 'donations_transferDonation',
      getUrl(item: { id: any }, _params: any) {
        return `/donation/${item.id}/transfer/setup`;
      }
    }
  },

  //-------------------------EventsTable
  EVENTS: {
    VIEW_DASHBOARD: {
      key: 'viewDashboard',
      getUrl(item: { id: any }, _params: any) {
        return `/events/dashboard/${item.id}`;
      }
    },
    EDIT: {
      key: 'events_edit',
      getUrl(item: { id: any }, _params: any) {
        return `/events/edit/${item.id}/setup`;
      }
    },
    VIEW: {
      key: 'events_view',
      getUrl(item: { urlFull: any }, _params: any) {
        return item.urlFull;
      },
      target: '_blank'
    },
    VIEW_ORGANIZATIONS: {
      key: 'events_view_organizations',
      getUrl(item: { id: any }, _params: any) {
        return `/events/view/organizations/${item.id}`;
      }
    },
    VIEW_DONATIONS: {
      key: 'events_view_donations',
      getUrl(item: { id: any }, _params: any) {
        return `/events/view/donations/${item.id}`;
      }
    },
    VIEW_FUNDRAISERS: {
      key: 'events_view_fundraisers',
      getUrl(item: { id: any }, _params: any) {
        return `/events/view/fundraisers/${item.id}`;
      }
    },
    CLONE_EVENT: {
      key: 'events_clone_event',
      getUrl(item: { id: any }, _params: any) {
        return `/events/clone/${item.id}/setup`;
      }
    },
    ACTIVE_INTEGRATION: {
      key: 'active_integration',
      getUrl(item: { id: any }, _params: any) {
        return `/events/active-integration/${item.id}/setup`;
      }
    },
    BACK_OFFICE: {
      key: 'backoffice'
    }
  },

  //-------------------------EventDetailsTable
  EVENTDETAILS: {
    EDIT_CAMPAIGN: {
      key: 'eventdetails_editCampaign',
      getUrl(item: { id: any }, _params: any) {
        return `/campaigns/edit/${item.id}/setup`;
      }
    },
    VIEW_PUBLIC_PAGE: {
      key: 'eventdetails_viewPublicPage',
      getUrl(item: { urlFull: any }, _params: any) {
        return item.urlFull;
      },
      target: '_blank'
    },
    VIEW_DONATIONS: {
      key: 'eventdetails_viewDonations',
      getUrl(item: { id: any }, _params: any) {
        return `/campaigns/view/donations/${item.id}`;
      }
    },
    VIEW_FUNDRAISERS: {
      key: 'eventdetails_viewFundraisers',
      getUrl(item: { id: any }, _params: any) {
        return `/campaigns/view/fundraisers/${item.id}`;
      }
    },
    EDIT_DONATION: {
      key: 'eventdetails_editDonation',
      getUrl(item: { id: any }, _params: any) {
        return `/donations/edit/${item.id}/donation-detail`;
      }
    },
    VIEW_DONATION: {
      key: 'donations_view',
      getUrl(item: { id: any }, _params: any) {
        return `/donations/view/${item.id}`;
      }
    },
    REFUND_DONATION: {
      key: 'eventdetails_refundDonation'
    },
    DELETE_DONATION: {
      key: 'eventdetails_deleteDonation'
    },
    REISSUE_RECEIPT: {
      key: 'eventdetails_reissueReceipt'
    },
    EDIT_PAGE: {
      key: 'eventdetails_editPage',
      getUrl(
        item: { type: any; fundraiserId: any; teamId: any; collectionId: any },
        _params: any
      ) {
        switch (item.type) {
          case pageTypesStrings.FUNDRAISER:
            return `/campaigns/fundraiser/edit/${item.fundraiserId}/page-detail`;
          case pageTypesStrings.TEAM:
            return `/campaigns/team/edit/${item.teamId}/page-detail`;
          case pageTypesStrings.COLLECTION:
            return `/campaigns/collection/edit/${item.collectionId}/page-detail`;
        }
      }
    },
    NAVIGATE_TO_PUBLIC: {
      key: 'eventdetails_navigateToPublicSite',
      getUrl(item: { urlFull: any }, _params: any) {
        return item.urlFull;
      },
      target: '_blank'
    },
    VIEW_PAGE_DONATIONS: {
      key: 'eventdetails_viewPageDonations',
      getUrl(
        item: {
          type: any;
          campaignId: any;
          fundraiserId: any;
          teamId: any;
          collectionId: any;
        },
        _params: any
      ) {
        switch (item.type) {
          case pageTypesStrings.FUNDRAISER:
            return `/campaigns/${item.campaignId}/fundraiser/view/donations/${item.fundraiserId}`;
          case pageTypesStrings.TEAM:
            return `/campaigns/${item.campaignId}/team/view/donations/${item.teamId}`;
          case pageTypesStrings.COLLECTION:
            return `/campaigns/${item.campaignId}/collection/view/donations/${item.collectionId}`;
        }
      }
    },
    VIEW_PAGE_FUNDRAISERS: {
      key: 'eventdetails_viewPageFundraisers',
      getUrl(
        item: { type: any; campaignId: any; teamId: any; collectionId: any },
        _params: any
      ) {
        switch (item.type) {
          case pageTypesStrings.TEAM:
            return `/campaigns/${item.campaignId}/team/view/fundraisers/${item.teamId}`;
          case pageTypesStrings.COLLECTION:
            return `/campaigns/${item.campaignId}/collection/view/fundraisers/${item.collectionId}`;
        }
      }
    }
  },

  //-------------------------OrganizationsTable
  ORGANIZATIONS: {
    VIEW_DASHBOARD: {
      key: 'viewDashboard',
      getUrl(item: { id: any }, _params: any) {
        return `/organizations/dashboard/${item.id}`;
      }
    },
    EDIT: {
      key: 'organizations_edit',
      getUrl(item: { id: any }, _params: any) {
        return `/organizations/edit/${item.id}/about`;
      }
    }
  },

  //-------------------------MerchandiseTable
  MERCHANDISE: {
    EDIT: {
      key: 'merchandise_edit',
      getUrl(item: { id: any }, _params: any) {
        return `/merchandise/edit/${item.id}/merchandise-detail`;
      }
    }
  },

  //-------------------------RecurringTable
  RECURRING: {
    EDIT: {
      key: 'recurring_edit',
      getUrl(item: { id: any }, _params: any) {
        return `/recurring/edit/${item.id}/recurring-detail`;
      }
    },
    CANCEL_DONATION: {
      key: 'recurring_close'
    },
    VIEW: {
      key: 'recurring_donations',
      getUrl(item: { id: any }, _params: any) {
        return `/recurring/detail/${item.id}/donations`;
      }
    },
    TRANSFER: {
      key: 'recurring_donation_transfer',
      getUrl(item: { id: any }, _params: any) {
        return `/recurring/transfer/${item.id}`;
      }
    },
    VIEW_PAGE_DONATIONS: {
      key: 'recurring_donations_refundDonation'
    }
  },

  //-------------------------ProgramTable
  PROGRAM: {
    VIEW_EVENT_PROGRAMS: {
      key: 'event_programs',
      getUrl(item: { eventId: any }, _params: any) {
        return `/vip-program/event/${item.eventId}/programs`;
      }
    },
    VIEW_CAMPAIGN_PROGRAMS: {
      key: 'campaign_programs',
      getUrl(item: { campaignId: any }, _params: any) {
        return `/vip-program/campaign/${item.campaignId}/applications`;
      }
    },
    EDIT_EVENT_PROGRAM: {
      key: 'edit_event_program',
      getUrl(item: { eventId: any }, _params: any) {
        return `/vip-program/edit/${item.eventId}/setup`;
      }
    },
    EDIT_CAMPAIGN_PROGRAM: {
      key: 'edit_campaign_program',
      getUrl(item: { campaignId: any }, _params: any) {
        return `/vip-program/campaign/edit/${item.campaignId}/setup`;
      }
    },
    TOGGLE_CAMPAIGN_PROGRAMS: {
      key: 'toggle_campaign_program',
    },
    CONFIRM_REGISTRATION: {
      key: 'confirm_registration',
    },
    EDIT_APPLICATION: {
      key: 'edit_application',
      getUrl(item: { id: any }, _params: any) {
        return `/vip-program/vip/${item.id}/part-one`;
      }
    },
    VIEW_APPLICATION_FORM: {
      key: 'view_application_form',
      getUrl(item: { urlFull: any }, _params: any) {
        return item.urlFull;
      },
      target: '_blank'
    },
    VIEW_FUNDRAISER: {
      key: 'view_fundraiser_page',
      getUrl(item: { fundraiser: { urlFull: any } }, _params: any) {
        return item.fundraiser.urlFull;
      },
      target: '_blank'
    }
  },

  //-------------------------Webhook table
  WEBHOOK_TABLE: {
    EDIT: {
      key: 'webhook_edit',
      getUrl(item: { id: any }, _params: any) {
        return `/webhooks/edit/${item.id}`;
      }
    },
    DELETE: {
      key: 'webhook_delete'
    }
  },

  //-------------------------Terminal table
  TERMINAL_TABLE: {
    UNASSIGN: {
      key: 'terminal_unassign'
    },
    ASSIGN: {
      key: 'terminal_assign'
    },
    EDIT: {
      key: 'terminal_edit'
    },
    DELETE: {
      key: 'terminal_delete'
    }
  }
};
