//------------React/Redux
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import React from 'react';
import { bindActionCreators } from 'redux';
import withDnDContext from '../formbuilder/HTML5BackendSingleton';
import { v4 as uuidv4 } from 'uuid';

//-------------Helpers
import * as validationHelper from '../../helpers/validationHelper';
import * as templateHelper from '../template/templateHelper';
import update from '../../helpers/update';
import get from 'lodash.get';

//-------------Components
import TicketComponent from './components/ticketComponent';
import { Message } from 'semantic-ui-react';
import { formBuilderActions } from '../../components/formbuilder/store';
import { cloneSection } from '../../components/formbuilder/formMappers';

//-------------Constants
import { i18Keys } from './translation';
import { TicketTypes } from '../../constants/ticketTypes';
import { paths } from './template';

//-------------Other
import './ticketing.css';

export class TicketBuilder extends React.Component {
  onItemChange = (index, fieldKey, value) => {
    const newTickets = this.getNewTickets(index, fieldKey, value);
    this.props.onChange(newTickets);
  };

  onPriceChange = (index, fieldKey, value) => {
    let valueCopy = this.getDeepCopy(this.props.value);

    valueCopy = update.set(valueCopy, `${index}.${fieldKey}`, value);

    if (!value) {
      valueCopy = update.set(valueCopy, `${index}.${paths.tax}`, false);
    }

    this.props.onChange(valueCopy);
  };

  onFreeTicketChange = (index, fieldKey, value) => {
    let valueCopy = this.getDeepCopy(this.props.value);

    valueCopy = update.set(valueCopy, `${index}.${fieldKey}`, value ? null : 0);

    if (value) {
      valueCopy = update.set(valueCopy, `${index}.${paths.tax}`, false);
    }

    this.props.onChange(valueCopy);
  };

  getDeepCopy = (value) => {
    return JSON.parse(JSON.stringify(value));
  };

  getNewTickets(index, fieldKey, value) {
    let valueCopy = this.getDeepCopy(this.props.value);

    valueCopy = update.set(valueCopy, `${index}.${fieldKey}`, value);

    return valueCopy;
  }

  isUnlimited = (item) => {
    const quantity = get(item, paths.quantity);
    return quantity === null;
  };

  isFree = (item) => {
    const price = get(item, 'pricing.price');
    return price === null;
  };

  getTicketItems = () => {
    const tickets = this.props.value;
    const path = this.props.path;

    if (!tickets) {
      return null;
    }

    return tickets.map((item, index) => {
      const identifier = get(item, 'template.value.identifier');
      let isExpanded = get(item, 'template.value.isExpanded');
      // get isExpanded, default to true if null/undefined
      if (isExpanded === null || isExpanded === undefined) {
        isExpanded = true;
      }
      return (
        <TicketComponent
          key={`${identifier}-${index}`}
          formBuilderKey={`Ticket-${identifier}`}
          index={index}
          path={path}
          isFree={this.isFree(item)}
          isUnlimited={this.isUnlimited(item)}
          {...item}
          moveTicket={this.moveTicket}
          onDeleteClick={this.onDeleteTicketClick}
          onChange={this.onItemChange}
          onPriceChange={this.onPriceChange}
          onFreeTicketChange={this.onFreeTicketChange}
          isQuestionsEnabled={this.props.isQuestionsEnabled}
          country={this.props.country}
          purchaseFundraisingStyle={this.props.purchaseFundraisingStyle}
          isSystemAdmin={this.props.isSystemAdmin}
          isExpanded={isExpanded}
          onCloneTicketClick={this.onAddTicketClick}
        />
      );
    });
  };

  appendCloneName = (newTicket, ticketList) => {
    const newName = ' - Clone';
    const items = ticketList.filter((x) => newTicket.name === x.name);
    if (items.length > 0) {
      newTicket.name += newName;
      this.appendCloneName(newTicket, ticketList);
    }
  };

  onAddTicketClick = (cloneIndex) => {
    const valueCopy = this.props.value
      ? this.getDeepCopy(this.props.value)
      : [];

    // if cloneIndex present, clone that value at that index
    if (cloneIndex >= 0 && valueCopy.length > cloneIndex) {
      const newTicket = this.getDeepCopy(this.props.value[cloneIndex]);
      newTicket.id = undefined;
      this.appendCloneName(newTicket, valueCopy);
      newTicket.template.value.isExpanded = false;
      // link up formBuilder
      const id = uuidv4();
      newTicket.template.value.identifier = id;
      const formBuilder = newTicket.template.value.formBuilder;
      if (formBuilder) {
        cloneSection(formBuilder, newTicket?.fields?.fields, `Ticket-${id}`);
        this.props.formBuilderActions.setFormBuilderModel(formBuilder);
      }
      valueCopy.splice(cloneIndex + 1, 0, newTicket);
    }
    // else create new ticket
    else {
      valueCopy.push({
        name: '',
        pricing: {
          price: 0,
          hasCountryTax: false
        },
        inventory: {
          quantity: 0
        },
        description: '',
        type: TicketTypes.Participant,
        stream: '',
        template: {
          value: {
            identifier: uuidv4()
          }
        },
        availableUntil: '',
        availableFrom: ''
      });
    }
    this.props.onChange(valueCopy);
  };

  onDeleteTicketClick = (index) => {
    const valueCopy = this.getDeepCopy(this.props.value);
    valueCopy.splice(index, 1);
    this.props.onChange(valueCopy);
  };

  moveTicket = (dragIndex, hoverIndex) => {
    let tickets = this.props.value;
    const dragTicket = tickets[dragIndex];

    tickets = update(tickets, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, dragTicket]
      ]
    });

    this.props.onChange(tickets);
  };

  render() {
    const ticketItems = this.getTicketItems();
    const i18Key =
      'template.page.content.ticketing.tickets.available-tickets.ticket';

    return (
      <div className="tickets-container">
        {ticketItems}
        <div className="add-ticket-container" onClick={this.onAddTicketClick}>
          <span className="add-ticket-text">
            {I18n.t(`${i18Key}.${i18Keys.ADD_NEW_TICKET}`)}
          </span>
        </div>
        <Message negative hidden={this.props.isValid}>
          <p>{this.props.errorMessage}</p>
        </Message>
      </div>
    );
  }
}

const mapState = (state, ownProps) => {
  const value = templateHelper.getValue(state.templateBuilderEx, ownProps.path);

  return {
    value: value,
    isValid: validationHelper.isValid(ownProps.validation, value),
    errorMessage: validationHelper.getValidationErrorMessage(
      ownProps.validation,
      value
    ),
    country: get(state, 'templateBuilderEx.data.organization.country'),
    purchaseFundraisingStyle: get(
      state,
      'templateBuilderEx.data.ticketing.purchaseFundraisingStyle'
    ),
    isSystemAdmin: get(state, 'session.isSystemAdmin')
  };
};

/** Maps the actions to properties */
const mapDispatch = (dispatch) => {
  return {
    formBuilderActions: bindActionCreators(formBuilderActions, dispatch)
  };
};

const withoutContext = connect(mapState, mapDispatch);
export default withoutContext(withDnDContext(TicketBuilder));
export { withoutContext };
