import React from 'react';
import { Form } from 'semantic-ui-react';

import { TemplatePage } from '../../../components/common/pager';
import { TemplateHorizontalRule } from '../../../components/template/templateHorizontalRule';
import { TemplateText } from '../../../components/template/templateText';
import { TemplateCheckbox } from '../../../components/template/templateCheckbox';
import { TemplateDatePicker } from '../../../components/template/templateDatePicker';
import { TemplateDropdown } from '../../../components/template/templateDropdown';
import { TemplateToggle } from '../../../components/template/templateToggle';
import FitnessActivities from '../../../components/common/fitnessElements/fitnessActivities';
import FitnessTrackedStatistics from '../../../components/common/fitnessElements/fitnessTrackedStatistics';
import FitnessThermometersToShow from '../../../components/common/fitnessElements/fitnessThermometersToShow';
import FitnessLeaderboardsToShow from '../../../components/common/fitnessElements/fitnessLeaderboardsToShow';
import FitnessPlatforms from '../../../components/common/fitnessElements/fitnessPlatforms';

import { campaignContract } from '../../campaign/create/templates';
import { TemplateCurrency } from '../../../components/template/templateCurrency';
import { fitnessMeasure, fitnessMeasureUnit } from '../../campaign/constants';
import { fitnessDateRuleOptions } from '../../../constants/metadataConstants';

//Full copy of campaign fitness tab
export const getFitnessTab = (props) => {
  const {
    isSystemAdmin,
    onChange,
    onChangeItemVisibility,
    fitnessDistanceTarget,
    targetValidation,
    fitnessTimeTarget,
    fitnessNumberOfStepsTarget,
    onAllowFitnessChange,
    fitnessActivitiesValidation,
    onAllowFundraiserAndTeamFitnessTargetsChange,
    onFundraiserFitnessTargetAmountChange,
    onTeamFitnessTargetAmountChange,
    fitnessTrackedStatisticsValidation,
    fitnessTargetsValidation
  } = props;

  return (
    <TemplatePage id="fitness" i18nKey="template.page.fitness">
      <TemplateCheckbox
        id={campaignContract.fitnessEnabled}
        path={campaignContract.fitnessEnabled}
        inputLabel="template.page.content.fitness.enabled.checkbox"
        onChange={onAllowFitnessChange}
      />

      <TemplateText
        label="template.page.content.fitness.enabled.subtext"
        size="small"
      />

      <TemplateHorizontalRule path={campaignContract.fitnessAllowedTypes} />
      <FitnessActivities
        path={campaignContract.fitnessAllowedTypes}
        onChange={onChange}
        validation={fitnessActivitiesValidation}
      />

      <TemplateHorizontalRule
        path={campaignContract.fitnessAllowedTrackedStatistics}
      />
      <FitnessTrackedStatistics
        path="fitnessSetup"
        onChange={onChange}
        validation={fitnessTrackedStatisticsValidation}
      />

      {/* <FitnessStatisticsToShow
						path={campaignContract.fitnessTargets}
						onChange={onChange}
						validation={fitnessTargetsValidation}
					/> */}

      <TemplateHorizontalRule
        path={campaignContract.fitnessThermometersToShow}
      />
      <FitnessThermometersToShow
        path={campaignContract.fitnessTargets}
        onChangeItemVisibility={onChangeItemVisibility}
        onChange={onChange}
        validation={fitnessTargetsValidation}
      />

      <TemplateHorizontalRule
        path={campaignContract.fitnessLeaderboardsToShow}
      />
      <FitnessLeaderboardsToShow
        path={campaignContract.fitnessLeaderboardsToShow}
        onChange={onChange}
      />

      <TemplateHorizontalRule path={campaignContract.fitnessAllowedPlatforms} />

      <FitnessPlatforms
        path={campaignContract.fitnessAllowedPlatforms}
        onChange={onChange}
      />

      <TemplateHorizontalRule path={campaignContract.allowPageTracking} />
      <TemplateCheckbox
        id={campaignContract.allowPageTracking}
        path={campaignContract.allowPageTracking}
        inputLabel="template.page.content.fitness.allowPageTracking.checkbox"
        onChange={onAllowFundraiserAndTeamFitnessTargetsChange}
      />

      <TemplateToggle path={campaignContract.allowPageTracking}>
        {isSystemAdmin && (
          <Form.Group widths="equal" path="fitnessFundraiserDates">
            <TemplateDatePicker
              path={campaignContract.fitnessValidFrom}
              label="template.page.content.fitness.fitnessThermometersToShow.targets.validFrom"
              onChange={onChange}
              onClear={() => onChange(campaignContract.fitnessValidFrom, null)}
              validation={{
                startDateRange: true,
                optionalParamPath: campaignContract.fitnessValidTo
              }}
            />

            <TemplateDatePicker
              path={campaignContract.fitnessValidTo}
              label="template.page.content.fitness.fitnessThermometersToShow.targets.validTo"
              onChange={onChange}
              onClear={() => onChange(campaignContract.fitnessValidTo, null)}
            />

            <TemplateDropdown
              className="valid-date-rule-dropdown"
              path={campaignContract.fitnessValidDateRule}
              label="template.page.content.fitness.fitnessThermometersToShow.targets.validDateRule"
              onChange={onChange}
              options={fitnessDateRuleOptions}
              hasEmptyOption
            />
          </Form.Group>
        )}

        <TemplateCurrency
          path="fitnessFundraiserDistanceTarget"
          validation={targetValidation}
          inputLabel={fitnessMeasureUnit.distance}
          value={
            fitnessDistanceTarget
              ? fitnessDistanceTarget.fundraiserDefaultTarget
              : ''
          }
          label="template.page.content.fitness.fitnessThermometersToShow.targets.fundraiserDistance"
          onChange={(_path, value) =>
            onFundraiserFitnessTargetAmountChange(fitnessMeasure.distance, value)
          }
        />

        <TemplateCurrency
          path="fitnessFundraiserTimeTarget"
          validation={targetValidation}
          inputLabel={fitnessMeasureUnit.time}
          value={
            fitnessTimeTarget ? fitnessTimeTarget.fundraiserDefaultTarget : ''
          }
          label="template.page.content.fitness.fitnessThermometersToShow.targets.fundraiserTime"
          onChange={(_path, value) =>
            onFundraiserFitnessTargetAmountChange(fitnessMeasure.time, value)
          }
        />

        <TemplateCurrency
          path="fitnessFundraiserNumberOfStepsTarget"
          validation={targetValidation}
          inputLabel={fitnessMeasureUnit.numberOfSteps}
          value={
            fitnessNumberOfStepsTarget
              ? fitnessNumberOfStepsTarget.fundraiserDefaultTarget
              : ''
          }
          label="template.page.content.fitness.fitnessThermometersToShow.targets.fundraiserNumberOfSteps"
          onChange={(_path, value) =>
            onFundraiserFitnessTargetAmountChange(
              fitnessMeasure.numberOfSteps,
              value
            )
          }
        />

        <TemplateCurrency
          path="fitnessTeamDistanceTarget"
          validation={targetValidation}
          inputLabel={fitnessMeasureUnit.distance}
          value={
            fitnessDistanceTarget ? fitnessDistanceTarget.teamDefaultTarget : ''
          }
          label="template.page.content.fitness.fitnessThermometersToShow.targets.teamDistance"
          onChange={(_path, value) =>
            onTeamFitnessTargetAmountChange(fitnessMeasure.distance, value)
          }
        />

        <TemplateCurrency
          path="fitnessTeamTimeTarget"
          validation={targetValidation}
          inputLabel={fitnessMeasureUnit.time}
          value={fitnessTimeTarget ? fitnessTimeTarget.teamDefaultTarget : ''}
          label="template.page.content.fitness.fitnessThermometersToShow.targets.teamTime"
          onChange={(_path, value) =>
            onTeamFitnessTargetAmountChange(fitnessMeasure.time, value)
          }
        />

        <TemplateCurrency
          path="fitnessTeamNumberOfStepsTarget"
          validation={targetValidation}
          inputLabel={fitnessMeasureUnit.numberOfSteps}
          value={
            fitnessNumberOfStepsTarget
              ? fitnessNumberOfStepsTarget.teamDefaultTarget
              : ''
          }
          label="template.page.content.fitness.fitnessThermometersToShow.targets.teamNumberOfSteps"
          onChange={(_path, value) =>
            onTeamFitnessTargetAmountChange(fitnessMeasure.numberOfSteps, value)
          }
        />
      </TemplateToggle>
    </TemplatePage>
  );
};
