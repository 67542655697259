import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Translate } from 'react-redux-i18n';

import { Record } from '../../../modules/bin/utility';

import * as recurringActions from '../reducer';
import * as templateBuilderActions from '../../../modules/template-builder-ex';

import BasePage from '../../../components/common/basePageView';
import Spinner from '../../../components/common/spinner';
import { TemplateOptions } from '../../../components/template/templateOptions';
import RecordResultMessage from '../../../components/common/recordResult';
import TransferDonationSubscruptionTemplate from './template';
import { paths } from './constants/template';

class TransferDonationSubscription extends Component {
  constructor(props) {
    super(props);
    props.recurringActions.getRecurringDonationRecord(props.match.params.id);
  }

  onSave = (data) => {
    this.props.recurringActions.createTransferDonationSubscription(data);
  };

  clearMessages = () => {
    this.props.recurringActions.clearRecordMessages('transferDonationSubscriptionRecord');
  };

  goToDonationSubscriptionsList = () => {
    this.props.history.push(`/recurring`);
  };

  initTemplateBuilderModel = () => {
    if (!this.props.isTemplateInited) {
      const donationSubscription = this.props.record.data.data;

      const model = new TemplateOptions()
        .setModel(donationSubscription)
        .setValue(paths.CAMPAIGN_ID, '')
        .setValue(paths.INCLUDE_EXISTING_PAYMENTS, false);

      this.props.templateBuilderActions.setTemplateModel(model.get());
    }
  };

  render() {
    const isLoading =
      Record.notStarted(this.props.record) ||
      Record.isRecordLoading(this.props.record);
    const isSaving = Record.isRecordLoading(this.props.transferDonationSubscriptionRecord);
    const isSuccess = Record.isSuccess(this.props.transferDonationSubscriptionRecord);

    if (isLoading) {
      return <Spinner />;
    }

    if (isSuccess) {
      this.goToDonationSubscriptionsList();
    }

    this.initTemplateBuilderModel();

    return (
      <BasePage>
        <RecordResultMessage
          record={this.props.transferDonationSubscriptionRecord}
          onDismiss={this.clearMessages}
        />

        <h1>
          <Translate value="template.page.content.donation.transfer-donation-subscription.title" />
        </h1>

        <TransferDonationSubscruptionTemplate onSave={this.onSave} isLoading={isSaving} />
      </BasePage>
    );
  }
}

const mapState = ({ recurring, templateBuilderEx }) => {
  return {
    record: recurring.record,
    transferDonationSubscriptionRecord: recurring.transferDonationSubscriptionRecord,
    isTemplateInited: templateBuilderEx.inited
  };
};

const mapDispatch = (dispatch) => {
  return {
    recurringActions: bindActionCreators(recurringActions, dispatch),
    templateBuilderActions: bindActionCreators(templateBuilderActions, dispatch)
  };
};

const TransferDonationSubscriptionContainer = withRouter(
  connect(mapState, mapDispatch)(TransferDonationSubscription)
);
export default TransferDonationSubscriptionContainer;
