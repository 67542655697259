import { I18n } from 'react-redux-i18n';
import { paymentMethod } from '../../../../constants/paymentMethod';

import {
  OFFLINE_DONATION_TYPE,
  ONLINE_DONATION_TYPE,
  PENDING_DONATION_TYPE,
  PLEDGE_DONATION_TYPE
} from '../../../campaign/constants';

export const donationI18Keys = {
  donationHeaderI18Key: 'donation.donation-view.donation-header',
  donationStatusTypesI18Key: 'donation.donation-view.status-types',
  donationShortDescriptionI18:
    'donation.donation-view.donation-short-description',
  donationActionI18: 'donation.donation-view.donation-action',
  donationDetailsI18Key: 'donation.donation-view.donation-details',
  donationPaymentDetailsI18Key:
    'donation.donation-view.donation-payment-details'
};

export const donationStatusBackgroundColor = {
  GREY: 'grey',
  ORANGE: '#e66708',
  GREEN: '#56b58b',
  LIGHTGREY: '#afb0b4'
};

export const donationSettings = {
  [OFFLINE_DONATION_TYPE]: {
    getPrimaryStatusBackgroundColor: (refunded, isPartialRefund) => {
      return getPrimaryStatusBackgroundColor(
        refunded,
        isPartialRefund,
        donationStatusBackgroundColor.GREY,
        donationStatusBackgroundColor.GREEN
      );
    },
    getSecondaryStatusBackgroundColor: () => {
      return donationStatusBackgroundColor.GREY;
    },
    getPrimaryStatusValue: (refunded, isPartialRefund) => {
      return getPrimaryStatusValue(refunded, isPartialRefund, 'refunded', 'partialRefund', 'successful');
    },
    getSecondaryStatusValue: () => {
      return getSecondaryStatusValue('offline');
    }
  },
  [ONLINE_DONATION_TYPE]: {
    getPrimaryStatusBackgroundColor: (refunded, isPartialRefund) => {
      return getPrimaryStatusBackgroundColor(
        refunded,
        isPartialRefund,
        donationStatusBackgroundColor.GREEN
      );
    },
    getSecondaryStatusBackgroundColor: () => {
      return '';
    },
    getPrimaryStatusValue: (refunded, isPartialRefund) => {
      return getPrimaryStatusValue(refunded, isPartialRefund, 'refunded', 'partialRefund', 'successful');
    },
    getSecondaryStatusValue: () => {
      return '';
    }
  },
  [PLEDGE_DONATION_TYPE]: {
    getPrimaryStatusBackgroundColor: (refunded, isPartialRefund) => {
      return getPrimaryStatusBackgroundColor(
        refunded,
        isPartialRefund,
        donationStatusBackgroundColor.GREEN
      );
    },
    getSecondaryStatusBackgroundColor: () => {
      return donationStatusBackgroundColor.GREY;
    },
    getPrimaryStatusValue: (refunded, isPartialRefund) => {
      return getPrimaryStatusValue(refunded, isPartialRefund, 'refunded', 'partialRefund', 'successful');
    },
    getSecondaryStatusValue: () => {
      return getSecondaryStatusValue('pledge');
    }
  },
  [PENDING_DONATION_TYPE]: {
    getPrimaryStatusBackgroundColor: (refunded, isPartialRefund) => {
      return getPrimaryStatusBackgroundColor(
        refunded,
        isPartialRefund,
        donationStatusBackgroundColor.ORANGE
      );
    },
    getSecondaryStatusBackgroundColor: () => {
      return '';
    },
    getPrimaryStatusValue: (refunded, isPartialRefund) => {
      return getPrimaryStatusValue(refunded, isPartialRefund, 'refunded', 'partialRefund', 'pending');
    },
    getSecondaryStatusValue: () => {
      return '';
    }
  }
};

export const getPrimaryStatusBackgroundColor = (
  isRefunded,
  isPartialRefund,
  defaultColor
) => {
  return isPartialRefund ? donationStatusBackgroundColor.LIGHTGREY : 
         isRefunded ? donationStatusBackgroundColor.GREY : defaultColor;
};

export const getPrimaryStatusValue = (
  isRefunded,
  isPartialRefund,
  refundedKey,
  partialRefundKey,
  otherCaseKey
) => {
  const donationStatusTypesI18Key = donationI18Keys.donationStatusTypesI18Key;

  return isPartialRefund ?
    I18n.t(`${donationStatusTypesI18Key}.${partialRefundKey}`) :
    isRefunded
    ? I18n.t(`${donationStatusTypesI18Key}.${refundedKey}`)
    : I18n.t(`${donationStatusTypesI18Key}.${otherCaseKey}`);
};

export const getSecondaryStatusValue = (I18Key) => {
  const donationStatusTypesI18Key = donationI18Keys.donationStatusTypesI18Key;

  return I18n.t(`${donationStatusTypesI18Key}.${I18Key}`);
};

export const getPaymentMethodValue = (value) => {
  if (value === paymentMethod.creditCard) {
    return I18n.t(`${donationI18Keys.donationDetailsI18Key}.credit-card`);
  }

  if (value === paymentMethod.bankAccount) {
    return I18n.t(`${donationI18Keys.donationDetailsI18Key}.bank-account`);
  }

  return 'N/A';
};
