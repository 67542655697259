export const paths = {
  CUSTOMER_FIRST_NAME: 'customer.firstName',
  CUSTOMER_LAST_NAME: 'customer.lastName',
  CUSTOMER_EMAIL: 'customer.email',
  TARGET_AMOUNT: 'amount',
  CURRENCY: 'currency',
  CAMPAIGN_ID: 'campaignId',
  PAGE_ID: 'pageId',
  FUNDRAISER_ID: 'fundraiserId',
  TEAM_ID: 'teamId',
  COLLECTION_ID: 'collectionId',
  PAGE_TYPE: 'pageType',
  PAGE: 'page'
};
