import { RSAA } from 'redux-api-middleware';
import update from '../../helpers/update';
import get from 'lodash.get';
import isEmpty from 'lodash/isEmpty';
import { cloneDeep } from 'lodash';
import * as utility from './utility';
import constants, { List as ListConstants } from '../../constants';
import recordConstants from '../../constants/record';
import { exportFileNames } from '../../constants/exportFiles';
import { verificationVariant } from '../../constants/multiFactorAuth';
import {
  convertFiltersToV2,
  convertFiltersToV3
} from '../../helpers/filterHelper';
import { isArrayWithItems } from '../../helpers/arrayHelper';

// ----------------------------------------------------------------
// REDUCERS

const updateListState = (state, action, msg) => {
  const listState = get(state, action.payload.key);
  const newListState = update(listState, msg);

  return update.set(state, action.payload.key, newListState);
};

export const updateRecordState = (state, stateKey = 'record', msg) => {
  const recordState = get(state, stateKey);
  const newRecordState = update(recordState, msg);
  return update.set(state, stateKey, newRecordState);
};

export const metadataRequestedHandler = (state, action) => {
  return updateListState(state, action, {
    timestamp: { $set: new Date().getTime() },
    metadata: {
      ready: { $set: ListConstants.LIST_METADATA_STATUS_LOADING }
    }
  });
};

export const metadataSuccessHandler = (state, action) => {
  return updateListState(state, action, {
    timestamp: { $set: new Date().getTime() },
    metadata: {
      ready: { $set: ListConstants.LIST_METADATA_STATUS_READY },
      columns: { $set: action.payload.data.columns },
      columnMap: { $set: action.payload.data.columnMap },
      filters: { $set: action.payload.data.filters },
      sort: { $set: action.payload.data.sort },
      toggle: { $set: action.payload.data.toggle }
    }
  });
};

export const listDataRequestedHandler = (state, action) => {
  const msg = {
    timestamp: { $set: new Date().getTime() },
    id: { $set: action.payload.id },
    ready: {
      $set: action.payload.fetchRequired
        ? ListConstants.LIST_STATUS_LOADING /* LOADING */
        : ListConstants.LIST_STATUS_READY /* LOADED */
    },
    request: { $set: action.payload.request }
  };

  return updateListState(state, action, msg);
};

export const listDataSuccessHandler = (state, action) => {
  const response = action.payload.data;

  return updateListState(state, action, {
    timestamp: { $set: new Date().getTime() },
    id: { $set: action.payload.id },
    ready: { $set: ListConstants.LIST_STATUS_READY } /* LOADED */,
    totalCount: { $set: response.totalCount },
    byPage: { [response.page]: { $set: response.list } }
  });
};

export const listDataFailureHandler = (state, action) => {
  const newState = update.set(
    state,
    `${action.payload.key}.timestamp`,
    new Date().getTime()
  );

  return update.set(
    newState,
    `${action.payload.key}.ready`,
    recordConstants.RECORD_STATUS_ERROR
  );
};

export const listMetadataFailureHandler = (state, action) => {
  const newState = update.set(
    state,
    `${action.payload.key}.timestamp`,
    new Date().getTime()
  );

  return update.set(
    newState,
    `${action.payload.key}.metadata.ready`,
    recordConstants.RECORD_STATUS_ERROR
  );
};

export const getRequestedHandler = (state, action) => {
  const recordKey = get(action, 'payload.key') || 'record';

  const record = get(state, recordKey);
  const newRecord = {
    ...record,
    id: null,
    ready: 1 /* :LOADING */,
    data: null,
    state: {}
  };
  return update.set(state, recordKey, newRecord);
};

export const getSuccessHandler = (state, action, recordKey) => {
  const data = get(action, 'payload.data');
  const id = get(action, 'payload.data.id');
  const path = recordKey || get(action, 'payload.key') || 'record';

  const record = get(state, path);
  const newRecord = {
    ...record,
    id: id,
    ready: 0 /* :LOADED */,
    data,
    state: {},
    success: {
      show: true
    }
  };
  return update.set(state, path, newRecord);
};

export const getRecordRequestedHandler = (state, action) => {
  return updateRecordState(state, action.payload.key, {
    ready: { $set: recordConstants.RECORD_STATUS_LOADING } /* :LOADING */
  });
};

export const getRecordSuccessHandler = (state, action, show = true) => {
  const data = get(action, 'payload.data');

  const id = get(action, 'payload.data.id') || null;

  return updateRecordState(state, action.payload.key, {
    id: { $set: id },
    ready: { $set: 0 } /* :LOADED */,
    data: { $set: data },
    state: { $set: {} },
    success: {
      show: { $set: show }
    }
  });
};

export const toggleColumnChangeHandler = (state, action) => {
  return updateListState(state, action, {
    timestamp: { $set: new Date().getTime() },
    columns: { $set: action.payload.columns }
  });
};

export const crudSuccessHandler = (state, action) => {
  return updateRecordState(state, action.payload.key, {
    success: {
      show: { $set: true }
    }
  });
};

export const messageErrorHandler = (state, action, errorKeys) => {
  return updateRecordState(state, action.payload.key, {
    error: { $set: { errorKeys: errorKeys, show: true } }
  });
};

export const crudErrorHandler = (state, action, recordKey = null) => {
  return utility.processHttpCodes(
    action,
    [
      {
        start: 400,
        handler: () => {
          let errorKeys = null;
          const error = get(action, 'payload.error');

          if (error) {
            errorKeys = error.map((item) => item.error);
          }

          return updateRecordState(state, recordKey || action.payload.key, {
            ready: { $set: recordConstants.RECORD_STATUS_ERROR },
            error: {
              show: { $set: true },
              title: { $set: action?.payload?.title },
              errorKeys: { $set: errorKeys }
            }
          });
        }
      },
      {
        start: 500,
        end: 599,
        handler: () => {
          let errorKeys = null;
          const error = get(action, 'payload.error');

          if (error) {
            errorKeys = error.map((item) => item.error);
          }

          return updateRecordState(state, recordKey || action.payload.key, {
            ready: { $set: recordConstants.RECORD_STATUS_ERROR },
            error: {
              show: { $set: true },
              errorKeys: { $set: errorKeys },
              title: { $set: action?.payload?.title },
              data: { $set: null }
            }
          });
        }
      }
    ],
    () => state
  );
};

export const crudClearErrorHandler = (state, key = 'record') => {
  return updateRecordState(state, key, {
    error: {
      show: { $set: false },
      data: { $set: null }
    },
    success: {
      show: { $set: false }
    }
  });
};

export const updateListItem = (state, id, key, template) => {
  let newState = state;
  const listPage = get(newState, `${key}.request.page`);
  if (!listPage) {
    throw new Error('Unknown key.');
  }
  const list = get(newState, `${key}.byPage[${listPage}]`);
  if (isEmpty(list)) {
    return;
  }
  const elementIndex = list.findIndex((element) => {
    return element.id === id;
  });
  const element = get(newState, `${key}.byPage[${listPage}][${elementIndex}]`);
  const newElement = update(element, template);
  return {
    timestamp: { $set: new Date().getTime() },
    byPage: {
      [listPage]: {
        [elementIndex]: {
          $merge: newElement
        }
      }
    }
  };
};

// ----------------------------------------------------------------------
// ACTIONS

export const toggleColumnsChange = (listKey, columns, actionType) => {
  return {
    type: actionType,
    payload: {
      key: listKey,
      columns: columns
    }
  };
};

export const executeRequest = (
  endpoint,
  method,
  actionTypeRequest,
  actionTypeSuccess,
  actionTypeFailure,
  data,
  beforeExecuteCallback,
  key,
  verificationToken = null,
  onSuccessCallback = null, 
) => {
  return (dispatch, getState) => {
    const state = getState();

    const msg = !data
      ? null
      : beforeExecuteCallback
      ? beforeExecuteCallback(state, data)
      : data;

    dispatch({
      [RSAA]: {
        endpoint,
        method,
        headers: {
          ApiKey: constants.apikey,
          Authorization: `bearer ${state.session.key}`,
          'Content-Type': 'application/json',
          Verification: verificationToken
        },
        body: msg ? JSON.stringify(msg) : null,
        types: [
          {
            type: actionTypeRequest,
            payload: async (action, state, res) => {
              return {
                key
              };
            }
          },
          {
            type: actionTypeSuccess,
            payload: async (action, state, res) => {
              let json = await res.json();

              if (onSuccessCallback) {
                json = await onSuccessCallback(state, json) || json;
              }

              return {
                ...json,
                key
              };
            }
          },
          {
            type: actionTypeFailure,
            payload: async (action, state, res) => {
              const json = await res.json();
              return {
                ...json,
                key,
                status: res.status
              };
            }
          }
        ]
      }
    });
  };
};

export const executeRecordRequest = (
  endpoint,
  method,
  actionTypeRequest,
  actionTypeSuccess,
  actionTypeFailure,
  recordKey,
  model,
  verificationToken,
  verificationMethod,
  verificationCode,
  fetchAll = false,
  currentPage = 1,
  allLoadedData = null
) => {
  return (dispatch, getState) => {
    const state = getState();
    if (model)
    {
      model.page = currentPage;
    }
    dispatch({
      [RSAA]: {
        endpoint: endpoint,
        method: method,
        body: model ? JSON.stringify(model) : null,
        headers: {
          ApiKey: constants.apikey,
          Authorization: `bearer ${state.session.key}`,
          'Content-Type': 'application/json',
          Verification: verificationToken,
          otp:
            verificationMethod === verificationVariant.authCode
              ? verificationCode
              : '',
          otpRecoveryCode:
            verificationMethod === verificationVariant.recoveryCode
              ? verificationCode
              : ''
        },
        types: [
          {
            type: actionTypeRequest,
            payload: (action, state) => ({ key: recordKey })
          },
          {
            type: actionTypeSuccess,
            payload: async (action, state, res) => {
              const payload = await res.json();
              if (fetchAll) {
                if (!allLoadedData) {
                  allLoadedData = payload;
                }
                else {
                  allLoadedData.data.list = [...allLoadedData.data.list, ...payload.data.list];
                }
                // do not load more than 5 pages
                const loadMoreData = payload.data.totalCount > allLoadedData.data.list.length
                  && currentPage <= 5;
                if (loadMoreData) {
                  currentPage += 1;
                  return executeRecordRequest(
                    endpoint,
                    method,
                    actionTypeRequest,
                    actionTypeSuccess,
                    actionTypeFailure,
                    recordKey,
                    model,
                    null,
                    null,
                    null,
                    fetchAll,
                    currentPage,
                    allLoadedData
                  )(dispatch, getState);
                }
              }
              return {
                key: recordKey,
                data: fetchAll ? allLoadedData : payload
              };
            }
          },
          {
            type: actionTypeFailure,
            payload: async (action, state, res) => {
              const json = await res.json();
              return {
                key: recordKey,
                status: res.status,
                ...json
              };
            }
          }
        ]
      }
    });
  };
};

export const deleteRecordRequest = (
  endpoint,
  actionTypeRequest,
  actionTypeSuccess,
  actionTypeFailure,
  recordKey,
  model,
  verificationToken,
  verificationCode,
  verificationMethod
) => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch({
      [RSAA]: {
        endpoint: endpoint,
        method: 'DELETE',
        body: model ? JSON.stringify(model) : null,
        headers: {
          ApiKey: constants.apikey,
          Authorization: `bearer ${state.session.key}`,
          'Content-Type': 'application/json',
          Verification: verificationToken ? verificationToken : '',
          otp:
            verificationMethod === verificationVariant.authCode
              ? verificationCode
              : '',
          otpRecoveryCode:
            verificationMethod === verificationVariant.recoveryCode
              ? verificationCode
              : ''
        },
        types: [
          {
            type: actionTypeRequest,
            payload: (action, state) => ({ key: recordKey })
          },
          {
            type: actionTypeSuccess,
            payload: async (action, state, res) => {
              return {
                key: recordKey
              };
            }
          },
          {
            type: actionTypeFailure,
            payload: async (action, state, res) => {
              const json = await res.json();
              return {
                key: recordKey,
                status: res.status,
                ...json
              };
            }
          }
        ]
      }
    });
  };
};

export const getMetadata = (
  entity,
  listKey,
  actionTypeRequest,
  actionTypeSuccess,
  actionTypeFailure
) => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch({
      [RSAA]: {
        endpoint: `${constants.baseApiHost}/api/v2/${entity}/metadata`,
        method: 'GET',
        headers: {
          ApiKey: constants.apikey,
          Authorization: `bearer ${state.session.key}`,
          'Content-Type': 'application/json'
        },
        types: [
          {
            type: actionTypeRequest,
            payload: (action, state) => ({ key: listKey })
          },
          {
            type: actionTypeSuccess,
            payload: async (action, state, res) => {
              const payload = await res.json();
              return getMetadataResponse(listKey, payload.data);
            }
          },
          {
            type: actionTypeFailure,
            payload: async (action, state, res) => {
              const json = await res.json();
              return {
                key: listKey,
                status: res.status,
                ...json
              };
            }
          }
        ]
      }
    });
  };
};

export const createAsyncExport = (
  entity,
  actionTypeRequest,
  actionTypeSuccess,
  actionTypeFailure,
  request,
  recordKey,
  fileName = exportFileNames.DEFAULT_NAME,
  version = 'v3'
) => {
  return (dispatch, getState) => {
    const state = getState();
    let converter = convertFiltersToV3;
    let newRequest = cloneDeep(request);
    const filters = get(newRequest, 'filters');

    // below tries and converts the request to match the version
    // If the body is unable and version has a miss-match it updates it to v3 to be safe
    if (version === 'v2') {
      // if v2 API is called but v3+ filter is requested, update to v3 API
      if (filters?.some((x) => isArrayWithItems(x.value, 2))) {
        version = 'v3';
      } else {
        // else convert to v2 request just to be safe
        converter = convertFiltersToV2;
      }
    }

    newRequest = update.set(newRequest, 'filters', converter(filters));

    dispatch({
      [RSAA]: {
        endpoint: `${constants.baseApiHost}/api/${version}/${entity}/export/async`,
        method: 'POST',
        body: newRequest ? JSON.stringify(newRequest) : null,
        headers: {
          ApiKey: constants.apikey,
          Authorization: `bearer ${state.session.key}`,
          'Content-Type': 'application/json',
          Accept: 'text/csv'
        },
        types: [
          {
            type: actionTypeRequest,
            payload: (action, state) => ({ key: recordKey })
          },
          {
            type: actionTypeSuccess,
            payload: async (action, state, res) => {
              const payload = await res.text();
              return {
                key: recordKey,
                data: payload
              };
            }
          },
          {
            type: actionTypeFailure,
            payload: async (action, state, res) => {
              const json = await res.json();
              return {
                key: recordKey,
                status: res.status,
                ...json
              };
            }
          }
        ]
      }
    });
  };
};

export const getAsyncExport = (
  entity,
  actionTypeRequest,
  actionTypeSuccess,
  actionTypeFailure,
  key,
  recordKey,
  version = 'v3'
) => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch({
      [RSAA]: {
        endpoint: `${constants.baseApiHost}/api/${version}/${entity}/export/async/${key}`,
        method: 'GET',
        body: null,
        headers: {
          ApiKey: constants.apikey,
          Authorization: `bearer ${state.session.key}`,
          'Content-Type': 'application/json',
          Accept: 'text/csv'
        },
        types: [
          {
            type: actionTypeRequest,
            payload: (action, state) => ({ key: recordKey })
          },
          {
            type: actionTypeSuccess,
            payload: async (action, state, res) => {
              const results = await res.json();
              const resourceUrl = results?.data?.resourceUrl;
              if (resourceUrl) {
                const link = document.createElement('a');
                link.href = resourceUrl;
                link.target = '_blank';
                link.setAttribute('download', exportFileNames.DEFAULT_NAME);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
              }
              return {
                key: recordKey,
                data: results
              };
            }
          },
          {
            type: actionTypeFailure,
            payload: async (action, state, res) => {
              const json = await res.json();
              return {
                key: recordKey,
                status: res.status,
                ...json
              };
            }
          }
        ]
      }
    });
  };
};

const getMetadataResponse = (listKey, metadata) => {
  const map = {};
  metadata.columns.forEach((item) => (map[item.key] = item));

  return {
    key: listKey,
    data: {
      columns: metadata.columns,
      columnMap: map,
      filters: metadata.filters,
      sort: metadata.sort,
      toggle: metadata.toggle
    }
  };
};

export const getListData = (
  entity,
  listKey,
  id,
  actionTypeRequest,
  actionTypeSuccess,
  actionTypeFailure,
  request,
  invalidate = false,
  cachedData,
  version = 'v2'
) => {
  const fetchRequired = invalidate || (cachedData && !cachedData[request.page]);

  if (fetchRequired === false) {
    return (dispatch) =>
      dispatch({
        type: actionTypeRequest,
        payload: { request, invalidate, fetchRequired }
      });
  }

  return (dispatch, getState) => {
    const state = getState();
    dispatch({
      [RSAA]: {
        endpoint: `${constants.baseApiHost}/api/${version}/${entity}/search`,
        method: 'POST',
        headers: {
          ApiKey: constants.apikey,
          Authorization: `bearer ${state.session.key}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(request),
        types: [
          {
            type: actionTypeRequest,
            payload: (action, state) => ({
              id,
              key: listKey,
              request,
              invalidate,
              fetchRequired
            })
          },
          {
            type: actionTypeSuccess,
            payload: async (action, state, res) => {
              const payload = await res.json();
              return Object.assign({}, payload, { id, key: listKey });
            }
          },
          {
            type: actionTypeFailure,
            payload: async (action, state, res) => {
              const json = await res.json();
              return {
                key: listKey,
                status: res.status,
                ...json
              };
            }
          }
        ]
      }
    });
  };
};

export const searchEntities = (
  entity,
  path,
  request,
  actionTypeRequest,
  actionTypeSuccess,
  actionTypeFailure
) => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch({
      [RSAA]: {
        endpoint: `${constants.baseApiHost}/api/v2/${entity}/search`,
        method: 'POST',
        headers: {
          ApiKey: constants.apikey,
          Authorization: `bearer ${state.session.key}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(request),
        types: [
          {
            type: actionTypeRequest,
            payload: (action, state) => {
              return {
                path,
                request,
                timestamp: new Date()
              };
            }
          },
          {
            type: actionTypeSuccess,
            payload: async (action, state, res) => {
              const payload = await res.json();
              return Object.assign({}, payload, {
                timestamp: new Date(),
                path
              });
            }
          },
          actionTypeFailure
        ]
      }
    });
  };
};

export const getPatchModel = (editableData) => {
  let patchModel = [];

  Object.keys(editableData).forEach((key) => {
    patchModel.push({
      op: 'replace',
      path: `/${key}`,
      value: editableData[key]
    });
  });

  return patchModel;
};

export const createMockRecord =
  (
    entity,
    actionTypeRequest,
    actionTypeSuccess,
    actionTypeFailure,
    record,
    beforeExecuteCallback,
    recordKey = 'record',
    version = 'v2/',
    verificationToken = null
  ) =>
  (dispatch) => {
    dispatch({
      type: actionTypeRequest,
      payload: {
        key: recordKey
      }
    });

    setTimeout(() => {
      dispatch({
        type: actionTypeSuccess,
        payload: {
          test: 'hello',
          key: recordKey
        }
      });
    }, 2000);
  };

export const getMockRecord =
  (
    entity,
    actionTypeRequest,
    actionTypeSuccess,
    actionTypeFailure,
    id,
    recordKey = 'record',
    version = 'v2'
  ) =>
  (dispatch) => {
    dispatch({
      type: actionTypeRequest,
      payload: {
        key: recordKey
      }
    });

    setTimeout(() => {
      dispatch({
        type: actionTypeFailure,
        payload: { key: recordKey, data: null, success: false, error: null }
      });
    }, 2000);
  };

export const createRecord = (
  entity,
  actionTypeRequest,
  actionTypeSuccess,
  actionTypeFailure,
  record,
  beforeExecuteCallback,
  recordKey = 'record',
  version = 'v2/',
  verificationToken,
  onSuccessCallback,
) =>
  executeRequest(
    `${constants.baseApiHost}/api/${version}${entity}`,
    'POST',
    actionTypeRequest,
    actionTypeSuccess,
    actionTypeFailure,
    record,
    beforeExecuteCallback,
    recordKey,
    verificationToken,
    onSuccessCallback,
  );

export const createBulkRecords = (
  entity,
  actionTypeRequest,
  actionTypeSuccess,
  actionTypeFailure,
  record,
  beforeExecuteCallback,
  recordKey = 'record',
  version = 'v2/',
  verificationToken = null
) =>
  executeRequest(
    `${constants.baseApiHost}/api/${version}${entity}/bulk`,
    'POST',
    actionTypeRequest,
    actionTypeSuccess,
    actionTypeFailure,
    record,
    beforeExecuteCallback,
    recordKey,
    verificationToken
  );

export const getRecord = (
  entity,
  actionTypeRequest,
  actionTypeSuccess,
  actionTypeFailure,
  id,
  recordKey = 'record',
  version = 'v2',
  suffix = ''
) => {
  const suffixPath = isEmpty(suffix) ? '' : `/${suffix}`;
  return executeRecordRequest(
    `${constants.baseApiHost}/api/${version}/${entity}/${id}${suffixPath}`,
    'GET',
    actionTypeRequest,
    actionTypeSuccess,
    actionTypeFailure,
    recordKey
  );
};

export const getSearchRecord = (
  entity,
  actionTypeRequest,
  actionTypeSuccess,
  actionTypeFailure,
  request,
  recordKey = 'record',
  version = 'v2',
  fetchAll = false
) =>
  executeRecordRequest(
    `${constants.baseApiHost}/api/${version}/${entity}/search`,
    'POST',
    actionTypeRequest,
    actionTypeSuccess,
    actionTypeFailure,
    recordKey,
    request,
    null,
    null,
    null,
    fetchAll
  );

export const getAggregate = (
  actionTypeRequest,
  actionTypeSuccess,
  actionTypeFailure,
  entity,
  recordKey = 'record'
) =>
  executeRecordRequest(
    `${constants.baseApiHost}/api/v2/${entity}`,
    'GET',
    actionTypeRequest,
    actionTypeSuccess,
    actionTypeFailure,
    recordKey
  );

export const patchRecord = (
  entity,
  actionTypeRequest,
  actionTypeSuccess,
  actionTypeFailure,
  id,
  editableData,
  recordKey = 'record',
  verificationMethod = null,
  verificationCode = null
) =>
  executeRecordRequest(
    `${constants.baseApiHost}/api/v2/${entity}/${id}`,
    'PATCH',
    actionTypeRequest,
    actionTypeSuccess,
    actionTypeFailure,
    recordKey,
    getPatchModel(editableData),
    verificationMethod,
    verificationCode
  );

export const updateRecord = (
  entity,
  actionTypeRequest,
  actionTypeSuccess,
  actionTypeFailure,
  id,
  record,
  beforeExecuteCallback,
  recordKey = 'record',
  onSuccessCallback,
) =>
  executeRequest(
    `${constants.baseApiHost}/api/v2/${entity}/${id}`,
    'PUT',
    actionTypeRequest,
    actionTypeSuccess,
    actionTypeFailure,
    record,
    beforeExecuteCallback,
    recordKey,
    null,
    onSuccessCallback,
  );

export const updateBulk = (
  entity,
  actionTypeRequest,
  actionTypeSuccess,
  actionTypeFailure,
  record,
  beforeExecuteCallback,
  recordKey = 'record'
) =>
  executeRequest(
    `${constants.baseApiHost}/api/v2/${entity}/bulk`,
    'PUT',
    actionTypeRequest,
    actionTypeSuccess,
    actionTypeFailure,
    record,
    beforeExecuteCallback,
    recordKey
  );

export const deleteRecord = (
  entity,
  actionTypeRequest,
  actionTypeSuccess,
  actionTypeFailure,
  id,
  recordKey = 'record',
  verificationToken,
  verificationCode = null,
  verificationMethod = null
) =>
  deleteRecordRequest(
    `${constants.baseApiHost}/api/v2/${entity}/${id}`,
    actionTypeRequest,
    actionTypeSuccess,
    actionTypeFailure,
    recordKey,
    null,
    verificationToken,
    verificationCode,
    verificationMethod
  );

export const deleteRecordByPath = (
  entity,
  path,
  actionTypeRequest,
  actionTypeSuccess,
  actionTypeFailure,
  id,
  recordKey = 'record',
  verificationToken = null,
  verificationCode = null,
  verificationMethod = null
) =>
  deleteRecordRequest(
    `${constants.baseApiHost}/api/v2/${entity}/${id}/${path}`,
    actionTypeRequest,
    actionTypeSuccess,
    actionTypeFailure,
    recordKey,
    null,
    verificationToken,
    verificationCode,
    verificationMethod
  );
